import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Selector } from '@/components/_common/FormInput';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
};

const defaultProps = {
  value: '',
  name: '',
  onChange: () => { },
  isInvalid: false,
};

function TownGrop({
  value, name, onChange, isInvalid,
}) {
  const [currentCity, setCurrentCity] = useState(0);
  const [currentTown, setCurrentTown] = useState(value || 0);
  const [cities, setCities] = useState([]);
  const [towns, setTowns] = useState([]);
  const defaultCityOption = [{ id: 0, name: '請選擇縣市' }];
  const defaultTownOption = [{ id: 0, name: '請選擇行政區域' }];

  // function fetchAllByDefault() {
  //   axios.get('/api/v2/address/fetch_all_options').then(({ data }) => {
  //     setCities(data.cities);
  //   });
  // }

  function fetchAllByTown(id) {
    axios
      .get(`/api/v2/address/fetch_all_options_by_town/${id}`)
      .then(({ data }) => {
        const { cities: citiesOption, towns: townsOption, current_town: currentLocation } = data;
        setCities(citiesOption);
        setTowns(townsOption || []);
        setCurrentTown(currentLocation.id);
        setCurrentCity(currentLocation.city_id);
      });
  }

  function fetchTownsByCity(city) {
    axios.get(`/api/v2/address/get_towns_by_city/${city}`).then(({ data }) => {
      setTowns(data);
    });
  }

  useEffect(() => {
    setCurrentTown(value || '0');
    fetchAllByTown(value || '');
  }, [value]);

  useEffect(() => {

  });

  // useEffect(() => {
  //   console.log('update currentStreet', currentStreet)
  //   props.onChange(props.name, currentStreet)
  // }, [currentStreet])

  function cityChangeHandler(e) {
    setCurrentCity(e.target.value);
    setCurrentTown(0);
    fetchTownsByCity(e.target.value);
  }

  return (
    <>
      <Col xs={6}>
        <Selector
          value={currentCity}
          name="city"
          options={cities && [...defaultCityOption, ...cities]}
          onChange={cityChangeHandler}
          isInvalid={isInvalid}
        />
      </Col>
      <Col xs={6}>
        <Selector
          value={currentTown}
          name={name}
          options={towns && [...defaultTownOption, ...towns]}
          onChange={onChange}
          isInvalid={isInvalid}
        />
      </Col>
    </>
  );
}

TownGrop.propTypes = propTypes;
TownGrop.defaultProps = defaultProps;

export default TownGrop;
