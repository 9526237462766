import useSWR from "swr"
import fetcher from '../../../components/fetcher';

const originsApi = (vehicleType) => `/member/policies/${vehicleType}_policies/vehicle_origins`
const brandsApi = (vehicleType) => `/member/policies/${vehicleType}_policies/vehicle_brands?car_origin_id=`
const carsApi = (vehicleType) => `/member/policies/${vehicleType}_policies/vehicles?car_brand_id=`
const carApi = (vehicleType) => `/member/policies/${vehicleType}_policies/vehicle?model=`

type Car = {
  id: number
  name: string
  model: string
}

type Origin = {
  id: number
  name: string
}

type Brand = {
  id: number
  name: string
}



const useCarBrand = (originId: string, brandId: string, vehicleType: string): { origins: Origin[], brands: Brand[], cars: Car[] } => {
  console.log(originId, brandId)
  const { data: origins, error: originsError } = useSWR<Origin[], any>(originsApi(vehicleType), fetcher, { revalidateOnFocus: false })
  const { data: brands, error: brandsError } = useSWR<Brand[], any>(brandsApi(vehicleType) + originId, fetcher, { revalidateOnFocus: false })
  const { data: cars, error: carsError } = useSWR<Car[], any>(brandId ? carsApi(vehicleType) + brandId : null, fetcher, { revalidateOnFocus: false })
  return { origins, brands, cars }
}

const useCarBrandBack = (carModel: string, vehicleType: string): { car: Car, origin: Origin, brand: Brand } => {
  const { data, error } = useSWR<{ car: Car, origin: Origin, brand: Brand }, any>(carModel && carApi(vehicleType) + carModel, fetcher, { revalidateOnFocus: false })
  return data;
}

export { useCarBrand, useCarBrandBack }