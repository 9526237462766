import * as React from "react";
import { PolicySetting } from "../../../policy";


type options = string[] | (string | number)[][]

type optionGroups = {
  [key: string]: string[] | (string | number)[][]
}

export interface BaseProps {
  disabled?: boolean
  label?: string
  name: string
  info?: string
  settings?: PolicySetting
}

export interface InputProps extends BaseProps {
  placeholder?: string;
  disableFormik?: boolean;
  onChange?: React.ChangeEventHandler
  onBlur?: React.FocusEventHandler
  value?: string
  type?: ("text" | "date" | "dateTime")
  settings?: PolicySetting
  right?: string
};

export interface RadioProps extends BaseProps {
  options?: options
  size?: ('default' | 'small')
  settings?: PolicySetting
}


export interface SelectProps extends BaseProps {
  options?: options | optionGroups
  size?: ('default' | 'small')
  defaultOption?: boolean
  settings?: PolicySetting
}

export interface FileInputProps extends BaseProps {
  accept?: string | undefined
}

export interface BoolProps extends BaseProps {
  inline?: boolean
  size?: ('default' | 'small')
}

export interface CheckBoxProps extends BaseProps {
  options?: options
  settings?: PolicySetting
}


export interface AgreementProps {
  name: string
  label?: string
  children: string | React.ReactElement | React.ReactElement[]
}


export interface DateInputProps extends InputProps {

}