/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MyPopup } from 'components/_common/StyledPopup';
import PolicyContext from '../../components/PolicyContext';
import TravelAnalysis from './analysis/TravelAnalysis';
import { PolicyEditContext } from '../../policy';

const getAge = (dateString: string): number => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

function AnalysisRead() {
  const { member, insured } = useContext(PolicyContext as React.Context<PolicyEditContext>);
  return (
    <MyPopup
      trigger={<a className="text-primary h5">SARAcares書面分析報告</a>}
      modal
    >
      <div>
        <h4 className="title-line-secondary">SARAcares書面分析報告</h4>
      </div>
      <Scrollbars className="text-gray" style={{ height: '100%', minHeight: '400px' }}>
        <h5>消費者基本資料</h5>
        <p>
          要保人：
          {member?.xname}
          {' '}
          ，性別：
          {member?.citizen_id[1] == '1' ? '男' : '女'}
          {' '}
          ，年齡：
          {' '}
          {getAge(member?.birthday)}
        </p>

        <p>
          被保險人：
          {insured?.name}
          {' '}
          ，性別：
          {insured?.citizen_id[1] == '1' ? '男' : '女'}
          {' '}
          ，年齡：
          {' '}
          {getAge(insured?.birthday)}
        </p>

        <TravelAnalysis />

        <p>備註</p>

        <ol>
          <li>
            本分析報告並非保險契約之一部分，本分析報告所建議內容，保險公司是否予以承保及詳細保險商品內容、費率及變更，需以要保文件及投保當時保單條款和保險公司作業規則為準。
          </li>
          <li>
            本分析報告係依據要、被保險人所提供各種資訊與需求所製作，並按製作時所可得之資訊而作出，本保險經紀人公司無法保證或承諾本分析報告內容完全正確無誤，抑或保險契約訂立後，將不因法令變更、金融保險市場變化或新金融保險相關資訊之衍生而有所變化或影響。
          </li>
        </ol>

        <p>
          保險經紀人公司名稱: 錠嵂保險經紀人股份有限公司
        </p>
        <p>
          保險經紀人公司營業所在地: 台北市松山區南京東路三段311號5樓
        </p>
      </Scrollbars>
    </MyPopup>
  );
}

export default AnalysisRead;
