import * as React from 'react';
import { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FC, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import OTPForm from '@/components/_common/OTPForm';
import Agreement from '../components/formObjects/Agreement';
import { PolicyProvider } from '../components/PolicyContext';
import KycRead from './components/KycRead';
import AnalysisRead from './components/AnalysisRead';
import PersonalCapitalRead from './components/PersonalCapitalRead';
import ContractRead from './components/ContractRead';
import themes from '@/components/Themes';
import confirmSchema from '../components/formObjects/validationSchema/confirmSchema';
import jwtHeader from '@/components/_common/jwtHeader';
import RedirectPopup from './components/RedirectPopup';
import { PolicyValues, Information, InsuranceCompany, Insured, Member } from '../policy';
// import { InitialValues, insuranceCompanyType, insuredType, memberType } from '@/components/pages/policy/components/IFormProps';



interface IProps {
  policy: PolicyValues<Information>
  insured: Insured
  member: Member
  popup: {
    kycRead: string
    personalCapital: string
    insurance_company: InsuranceCompany
  }
  next: string
  update: boolean
  payment: any
}


const Edit: FC<IProps> = ({
  policy, insured, member, popup, next, payment
}) => {
  const [otpFormOpen, setOtpFormOpen] = useState(policy.filing_status === "completed");
  const [paymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [paymentForm, setPaymentForm] = useState(payment);

  const handleVerified = () => {
    setOtpFormOpen(false);
    setPaymentPopupOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      policy: {
        is_policy_content_read: policy.is_policy_content_read || false,
        is_kyc_read: policy.is_kyc_read || false,
        is_contract_read: policy.is_contract_read || false,
        is_analysis_read: policy.is_analysis_read || false,
        is_personal_capital_read: policy.is_personal_capital_read || false,
      },
    },
    validationSchema: confirmSchema,
    validateOnBlur: false,
    onSubmit: (values, actions) => {
      console.log(values);
      window.showLoading();
      fetch(next, { method: 'PUT', headers: jwtHeader(), body: JSON.stringify(values) })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res.success) {
            window.hideLoading();
            setOtpFormOpen(true);
            setPaymentForm(res.payment);
          } else {
            window.location.reload();
            window.scrollTo(0, 0);
          }
        });
    },
  });

  return (
    <ThemeProvider theme={themes}>
      <PolicyProvider value={{
        popup, insured, member, policy,
      }}
      >
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} id="policy_form">
            <Agreement name="policy[is_policy_content_read]">
              <span className="h5">我了解以上保單內容</span>
            </Agreement>
            <Agreement name="policy[is_kyc_read]">
              <span className="h5">我了解</span>
              <KycRead />
            </Agreement>
            <Agreement name="policy[is_contract_read]">
              <span className="h5">我了解</span>
              <ContractRead packageId={policy.package_id} />
            </Agreement>
            <Agreement name="policy[is_analysis_read]">
              <span className="h5">我同意</span>
              <AnalysisRead />
            </Agreement>
            <Agreement name="policy[is_personal_capital_read]">
              <span className="h5">我已同意</span>
              <PersonalCapitalRead />
            </Agreement>
            <p className="text-primary">配合法令規範，本公司將進行隨機抽樣電話訪問，確保您了解商品內容及投保意願。 </p>
            <div className="text-center py-4">
              <Button
                onClick={() => {
                  window.location.href = (window.location.href.replace(/\/[^\/]*$/, '/back_status'))
                }}
                className="px-4 py-2 m-3 h4"
              >
                上一步
              </Button>
              <Button variant="outline-primary" type="submit" className="px-4 py-2 m-3 h4">
                下一步
              </Button>
            </div>
          </Form>
          {otpFormOpen ? <OTPForm mobilePhone={member.mobile_phone} disabledClose onSuccess={handleVerified} /> : null}
          {paymentPopupOpen ? <RedirectPopup paymentForm={paymentForm} /> : null}
        </FormikProvider>
      </PolicyProvider>
    </ThemeProvider>
  );
}

export default Edit;
