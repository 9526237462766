import { setIn, useField, useFormikContext, yupToFormErrors } from 'formik';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ResponsiveEmbed, Row } from 'react-bootstrap';
import Input from '../../../components/formObjects/Input';
import salesNumberSchema from '@/components/pages/policy/components/formObjects/validationSchema/salesNumberSchema'
import promiseDebounce from '@/units/promiseDebounce';
import { InitialValues } from '../../../policy';
import { debounce } from 'lodash'

interface Response {
  error_message?: string
  error?: string
  name?: string
}


const reg = /(?![0-9a-zA-Z])./g;

const fieldName = 'policy[sales_number]';


const OtherForm = () => {
  const { values: { policy: { member_id: memberId } } } = useFormikContext<InitialValues<any>>();

  const [info, setInfo] = useState('');
  const [checkValue, setCheckValue] = useState('')

  const checkSales = async (salesNo: string): Promise<string> => {
    console.log(salesNo)
    if (!touched && checkValue == salesNo) return null
    setCheckValue(salesNo);
    if (!salesNo) return null
    console.log("has value")
    if (salesNo.length < 5) {
      setInfo("")
      return "格式錯誤"
    }
    console.log("length >= 5")

    try {
      const response: Response = await fetch('/verify_sales_numbers', {
        method: 'POST',
        body: JSON.stringify({ eno: salesNo, member_id: memberId }),
        headers: { 'Content-Type': 'application/json' },
      }).then(res => res.json())

      if (response.error_message) {
        setInfo("")
        return response.error_message
      }
      if (response.name) {
        setInfo(response.name)
        return null
      }
      setInfo("")
    } catch (e) {
      setInfo("")
      return "網路錯誤 請稍後再試"
    }

  }

  // debounce會卡住所有validation 慢慢研究
  const debounceCheckSales = promiseDebounce(checkSales, 1000)

  const [{ value, onBlur }, { error, touched, }, { setValue, setError }] = useField({ name: fieldName, validate: checkSales });





  useEffect(() => {
    checkSales(value)
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value.toUpperCase().replace(reg, '');
    if (value.length > 7) {
      value = value.slice(0, -1)
    }
    setValue(value);
  };

  const handleBlur = (e: React.TouchEvent<HTMLInputElement>): void => {
    onBlur(e)
  }



  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">其他資訊</h2>
      </div>
      <Input label="推薦代碼" value={value} info={info} name={fieldName} onChange={handleChange} onBlur={onBlur} disableFormik />
      <Row>
        <div className="col-12 offset-md-2 offset-0 col-md-6 col-12">
          <p className="text-primary">非錠嵂業務及平台合作業者推薦，無需填寫</p>
        </div>
      </Row>
    </div>
  );
}

export default OtherForm;
