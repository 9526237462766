import { ChangeEvent, useEffect, useState } from "react"

type UseOccupationProps = {
  defaultValue: string
  handleChange: (v: string) => void
}

type Options = {
  types: any[], classes: any[], occupations: any[]
}

type Values = {
  type: string,
  class: string,
  occupation: string
}

type ReturnType = {
  code: string, options: Options, values: Values, isLoading: Boolean, handleCodeChange: (e: ChangeEvent) => void
}



const useOccupation = (defaultValue: string, handleChange: (v: string) => void): ReturnType => {
  const [code, setCode] = useState<string>(defaultValue || '')
  const [values, setValues] = useState<Values>({ type: "", class: "", occupation: "" })
  const [options, setOptions] = useState<Options>({ types: [], classes: [], occupations: [] })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (code && code.length < 5) {
      setIsLoading(true)
      fetch("/occupations.json?" + (code && `code=${code}`), {
        method: "GET",
      }).then((res) => res.json()).then((json) => {
        setIsLoading(false)
        if (code == "") {
          return setOptions({ ...options, types: json })
        }
        if (code.length == 2) {
          return setOptions(prev => ({ ...prev, classes: json }))
        }
        if (code.length == 4) {
          let occOptions = json.map(j => [j.description, j.code])
          return setOptions(prev => ({ ...prev, occupations: occOptions }))
        }
      })
    }
  }, [code])



  useEffect(() => {
    setIsLoading(true)
    const fetchResetData = async () => {
      const [types, classes, occupations] = await Promise.all([
        fetch("/occupations.json").then(res => res.json()),
        fetch(`/occupations.json?code=${defaultValue.slice(0, 2)}`).then(res => res.json()),
        fetch(`/occupations.json?code=${defaultValue.slice(0, 4)}`).then(res => res.json())
      ])
      setOptions({ types, classes, occupations: occupations.map(j => [j.description, j.code]) })
      setValues({ type: defaultValue.slice(0, 2), class: defaultValue.slice(0, 4), occupation: defaultValue })
      setIsLoading(false)
    }
    if (defaultValue && defaultValue.length == 8) {
      fetchResetData()
    } else {
      fetch("/occupations.json").then(res => res.json())
        .then(res => {
          console.log(res)
          setIsLoading(false);
          setOptions({ ...options, types: res })
        })
    }
  }, [defaultValue])

  const handleCodeChange = (e) => {
    let value = e.target.value
    if (value.length == 2) {
      if (value.type == value) return
      setValues({ type: value, class: "", occupation: "" })
    } else if (value.length == 4) {
      if (value.class == value) return
      setValues(prev => ({ ...prev, class: value, occupation: "" }))
    } else {
      if (value.occupation == value) return
      setValues(prev => ({ ...prev, occupation: value }))
      console.log(value)
      handleChange(value)
    }
    setCode(value)
  }

  return {
    code, options, values, isLoading, handleCodeChange
  }



}

export default useOccupation