import * as React from 'react';
import BasicInfo from './BasicInfo';
import InsuredForm from '../components/subForm/InsuredForm';
import KycsForm from '../components/subForm/KycsForm';

const PetForm = () => (
  <>
    <BasicInfo />
    <InsuredForm />
    <KycsForm />
  </>
);

export default PetForm;
