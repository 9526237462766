import React, { useEffect, useState } from 'react'
import MemberContext from '@/components/_context/MemberContext'
import {
  Input,
  MyInputGroup as InputGroup,
  Selector,
} from '@/components/_common/FormInput'
import { Formik, useFormik, FormikProvider } from 'formik'
import { Form, Col, Row, Button } from 'react-bootstrap'
import axios from 'axios'
import MobileVerifies from '../MobileVerifies'
import * as yup from 'yup'
import moment from 'moment'
import TownGrop from '../../common/TownGrop'
import BirthdayInput from './BirthdayInput'
import jwtHeader from '@/components/_common/jwtHeader'
import OccupationInput from './OccupationInput'

const phoneRegExp = /09[0-9]{8}/
const addressRegExp = /[0-9]+\D+/
const nameRegExp = /^(?=.*[\u4e00-\u9fa5\u3400-\u4DBF\uF900-\uFAFF])[\u4e00-\u9fa5\u3400-\u4DBF\uF900-\uFAFF_a-zA-Z,\s-]{2,}$/

const schema = yup.object().shape({
  member: yup.object().shape({
    xname: yup.string().required('請輸入姓名').matches(nameRegExp, '請輸入您身分證上的姓名'),
    mobile_phone: yup
      .string()
      .required('請輸入行動電話')
      .matches(phoneRegExp, '電話格式錯誤'),
    birthday: yup
      .date()
      .typeError('格式錯誤 例：1990-01-01')
      .max(moment().subtract(18, 'years').toDate(), '須年滿18才可使用本網站')
      .required('請輸入生日 例：1990-01-01'),
    email: yup.string().email('Email 格式錯誤'),
    town_id: yup.number().required('請選擇鄉鎮市區').min(1, '請選擇鄉鎮市區'),
    address: yup
      .string()
      .required('地址格式錯誤')
      .matches(/^((?![0-9][F|f]).)+$/, '樓層請使用中文「樓」')
      .matches(
        /^((?![樓|號]之[0-9a-zA-Z]*[樓|室]).)+$/,
        '請使用逗號區隔地址，例如：15號之1，5樓之1，A室',
      )
      .matches(/^((?!,).)+$/, '請使用全形逗號')
      .matches(addressRegExp, '地址格式錯誤'),
  }),
})

function Info() {
  const [user, setUser] = useState()
  const [mobileVarify, setMobileVarify] = useState({
    varifyId: '',
    varifyPhone: '',
    varifyPopupTrigger: false,
  })

  const varifyPopupCloseHandler = (() => {
    setMobileVarify({ ...mobileVarify, varifyPopupTrigger: false })
  })

  const formik = useFormik({
    initialValues: {
      utf8: '✓',
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      member: {
        citizen_id: (user && user.citizen_id) || '',
        xname: (user && user.xname) || '',
        birthday: (user && user.birthday) || '',
        mobile_phone: (user && user.mobile_phone) || '',
        town_id: (user && user.town_id) || '',
        occupation_code: (user && user.occupation_code) || '',
        address: (user && user.address) || '',
        email: (user && user.email) || '',
      },
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      fetch('/member/mobile_verifies/identity.json', { method: "POST", headers: jwtHeader(), body: JSON.stringify(values) })
        .then(res => res.json())
        .then((res) => {
          console.log(res)
          setMobileVarify({
            varifyId: res.mobile_verify.id,
            varifyPhone: res.mobile_verify.phone_number,
            varifyPopupTrigger: true,
            varifyPrefix: res.mobile_verify.prefix + '-',
            submitData: res.member_data
          })
        })
    }
  })

  useEffect(() => {
    fetch('/member/edit.json')
      .then(res => res.json())
      .then(res => {
        console.log(res)
        if (res.current_member) {
          setUser(res.current_member)
        } else {
          throw '系統異常，請稍後再試'
        }
      })
      .catch(() => {
        alert('系統異常，請稍後再試')
      })
    return setUser(null)
  }, [])

  return (
    <FormikProvider value={formik} >
      <div className="mt-3">
        <Form className="mx-0 mx-md-3 mt-4" onSubmit={formik.handleSubmit}>
          <Input
            label="身分證字號"
            value={formik.values.member.citizen_id}
            type="text"
            name="citizen_id"
            onChange={() => { }}
            disabled
          ></Input>

          <Input
            label="姓名"
            value={formik.values.member.xname}
            type="text"
            name="member[xname]"
            onChange={formik.handleChange}
            placeholder={formik.initialValues.member.xname}
            errors={'member' in formik.errors && formik.errors.member.xname}
          ></Input>

          <BirthdayInput
            label="出生日期"
            value={formik.values.member.birthday}
            type="text"
            name="member[birthday]"
            onChange={formik.handleChange}
            placeholder={formik.initialValues.member.birthday}
            errors={'member' in formik.errors && formik.errors.member.birthday}
          ></BirthdayInput>

          <Input
            label="行動電話"
            value={formik.values.member.mobile_phone}
            type="text"
            name="member[mobile_phone]"
            onChange={formik.handleChange}
            placeholder={formik.initialValues.member.mobile_phone}
            errors={'member' in formik.errors && formik.errors.member.mobile_phone}
          ></Input>

          <Input
            label="電子郵件"
            value={formik.values.member.email}
            type="text"
            name="member[email]"
            onChange={formik.handleChange}
            placeholder={formik.initialValues.member.email}
            errors={'member' in formik.errors && formik.errors.member.email}
          ></Input>

          <OccupationInput
            name="member[occupation_code]"
          />
          <div className="d-block invalid-feedback">
            {'occupation_code' in formik.errors && formik.errors.member.occupation_code}
          </div>

          <InputGroup label="通訊地址" name="town_id">
            <Row>
              <TownGrop
                value={formik.values.member.town_id}
                onChange={formik.handleChange}
                name="member[town_id]"
                isInvalid={'member' in formik.errors && formik.errors.member.town_id}
              ></TownGrop>
              <Col xs={12} className="mt-2">
                <Form.Control
                  type="text"
                  value={formik.values.member.address}
                  name="member[address]"
                  onChange={formik.handleChange}
                  placeholder={formik.initialValues.member.address}
                  isInvalid={'member' in formik.errors && formik.errors.member.address}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {'member' in formik.errors && formik.errors.member.address}
                </Form.Control.Feedback>
                <div className="d-block invalid-feedback">
                  {'member' in formik.errors && formik.errors.member.town_id}
                </div>
                <div>
                  <p className="text-primary">
                    請務必正確填寫，且不得為郵政信箱。
                  </p>
                </div>
              </Col>
            </Row>
          </InputGroup>

          <Row className="mt-5 mb-4">
            <Col xs={6} md={2} className="ml-auto">
              <Button
                variant="outline-gray-500"
                onClick={formik.resetForm}
                block
              >
                {' '}
                取消{' '}
              </Button>
            </Col>
            <Col xs={6} md={2} className="mr-auto">
              <Button variant="primary" type="submit" block>
                {' '}
                完成{' '}
              </Button>
            </Col>
          </Row>
        </Form>
        <MobileVerifies
          id={mobileVarify.varifyId}
          phone={mobileVarify.varifyPhone}
          trigger={mobileVarify.varifyPopupTrigger}
          prefix={mobileVarify.varifyPrefix}
          onClose={varifyPopupCloseHandler}
          params={mobileVarify.submitData}
        ></MobileVerifies>
      </div>
    </FormikProvider>
  )
}

// export class Info extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       towns: [],
//       cities: [],
//       current_member: {},
//       currentCity: 0,
//       currentTowns: [],
//       currentTowns: 0,
//       varifyId: '',
//       varifyPhone: '',
//       varifyPopupTrigger: false,
//       mask: [
//         /[1-2]/,
//         /(0|9)/,
//         /[0-9]/,
//         /[0-9]/,
//         /-/,
//         /[0-1]/,
//         /[0-9]/,
//         /-/,
//         /[0-3]/,
//         /[0-9]/,
//       ],
//     }
//   }

//   componentDidMount() {
//     axios
//       .get('/member/edit.json', {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       })
//       .then((response) => {
//         // let currentCity = this.getCityValue(response.data.current_member.town)
//         // let currentTowns = this.getCurrentTownOptions(
//         //   currentCity,
//         //   response.data.towns,
//         // )
//         this.setState({
//           ...response.data,
//           current_member: {
//             citizen_id: response.data.current_member.citizen_id || '',
//             xname: response.data.current_member.xname || '',
//             birthday: response.data.current_member.birthday || '',
//             mobile_phone: response.data.current_member.mobile_phone || '',
//             town_id: response.data.current_member.town_id || '',
//             address: response.data.current_member.address || '',
//             email: response.data.current_member.email || '',
//             town: response.data.town || '',
//           },
//           //currentCity: currentCity,
//           //currentTowns: currentTowns,
//           submitData: {},
//         })
//       })
//   }

//   varifyPopupCloseHandler() {
//     this.setState({
//       varifyPopupTrigger: false,
//     })
//   }

//   getCurrentTownOptions(getCurrentTownOptins) {
//     let towns = []
//     _towns.forEach((town) => {
//       if (currentCityId == town.city_id) {
//         let t = { ...town }
//         t.id = t.postal_code
//         delete t.postal_code
//         towns.push(t)
//       }
//     })
//     return towns
//   }

//   getCityValue(postalCode, towns) {
//     let city_code = towns.find((c) => c.postal_code == postalCode).city_id || 0
//     return city_code
//   }

//   cityChangeHandler(e) {
//     let currentCity = e.target.value
//     let currentTowns = this.getCurrentTownOptions(currentCity, this.state.towns)
//     this.setState({
//       currentCity: e.target.value,
//       currentTowns: currentTowns,
//     })
//   }

//   mobile_varify(varifyData) {
//     this.setState({
//       varifyId: varifyData.id,
//       varifyPhone: varifyData.phone_number,
//       varifyPopupTrigger: true,
//       varifyPrefix: varifyData.prefix + '-',
//     })
//   }

//   beforeMaskedValueChange(newState, oldState, userInput) {
//     let { value, selection } = newState
//     let mask = this.state.mask
//     if (selection != null && selection.end != null) {
//       let end = selection.end
//       if (end == 5 || end == 8) {
//         end -= 1
//       }
//       if (!RegExp(mask[end - 1]).test(value[end - 1])) {
//         return oldState
//       }

//       //日
//       if (selection.end == 9) {
//         let Tens = value[8]
//         Tens == 3
//           ? (mask[9] = /(0|1)/)
//           : Tens == 0
//             ? (mask[9] = /[1-9]/)
//             : (mask[9] = /[0-9]/)
//       }
//       //月
//       if (selection.end == 6) {
//         let Tens = value[5]
//         Tens == 1 ? (mask[6] = /[0-2]/) : (mask[6] = /[1-9]/)
//       }
//       //年 百位數
//       if (selection.end == 1) {
//         let th = value[0]
//         th == 2 ? (mask[1] = /0/) : (mask[1] = /9/)
//       }
//     }
//     if (this.state.mask != mask) {
//       this.setState({ mask })
//     }
//     return newState
//   }

//   addressChnageHandler(e, handleChange) {
//     let address = e.target.value
//     // if (this.isOnComposition) return
//     address = address.replace(/[-|－|−|-]/, '之')
//     address = address.replace(/[0-9][f|F]/, (str) => str.replace(/[f|F]/, '樓'))
//     address = address.replace(/,/, '，')
//     address = address.replace(/零/, '0')
//     address = address.replace(/一/, '1')
//     address = address.replace(/二/, '2')
//     address = address.replace(/三/, '3')
//     address = address.replace(/四/, '4')
//     address = address.replace(/五/, '5')
//     address = address.replace(/六/, '6')
//     address = address.replace(/七/, '7')
//     address = address.replace(/八/, '8')
//     address = address.replace(/九/, '9')
//     address = address.replace(/十/, '1')
//     address = address.replace(/之[1-9] /, (str) => str.replace(/ /, '，'))
//     address = address.replace(/ /, '')
//     if (e.target.value != address) {
//       e.target.value = address
//     }
//     handleChange(e)
//   }

//   render() {
//     let { current_member } = this.state
//     return (
//       <div className="mt-3">
//         <Formik
//           enableReinitialize
//           validationSchema={schema}
//           initialValues={{
//             utf8: '✓',
//             authenticity_token: document
//               .querySelector("meta[name='csrf-token']")
//               .getAttribute('content'),
//             member: {
//               citizen_id: (current_member && current_member.citizen_id) || '',
//               xname: (current_member && current_member.xname) || '',
//               birthday: (current_member && current_member.birthday) || '',
//               mobile_phone:
//                 (current_member && current_member.mobile_phone) || '',
//               town_id: (current_member && current_member.town_id) || '',
//               address: (current_member && current_member.address) || '',
//               email: (current_member && current_member.email) || '',
//             },
//           }}
//           onSubmit={(values, actions) => {
//             let _this = this
//             this.setState({ submitData: values.member })
//             showLoading()
//             axios
//               .post('/member/mobile_verifies/identity.json', values, {
//                 headers: {
//                   'Content-Type': 'application/json',
//                 },
//               })
//               .then((response) => {
//                 _this.mobile_varify(response.data.mobile_verify)
//               })
//               .catch(function (error) { })
//               .then(function () {
//                 hideLoading()
//                 actions.setSubmitting(false)
//               })
//           }}
//         >
//           {({
//             values,
//             setFieldValue,
//             handleChange,
//             errors,
//             handleSubmit,
//             initialValues,
//             resetForm,
//           }) => {
//             return (
//               <Form className="mx-0 mx-md-3 mt-4" onSubmit={handleSubmit}>
//                 <Input
//                   label="身分證字號"
//                   value={values.member.citizen_id}
//                   type="text"
//                   name="citizen_id"
//                   onChange={() => { }}
//                   disabled
//                 ></Input>

//                 <Input
//                   label="姓名"
//                   value={values.member.xname}
//                   type="text"
//                   name="member[xname]"
//                   onChange={handleChange}
//                   placeholder={values.member.xname}
//                   errors={'member' in errors && errors.member.xname}
//                 ></Input>

//                 <Input
//                   label="出生日期"
//                   value={values.member.birthday}
//                   type="text"
//                   name="member[birthday]"
//                   onChange={handleChange}
//                   placeholder={initialValues.member.birthday}
//                   mask="9999-99-99"
//                   maskChar=" "
//                   errors={'member' in errors && errors.member.birthday}
//                   beforeMaskedValueChange={this.beforeMaskedValueChange.bind(
//                     this,
//                   )}
//                 ></Input>

//                 <Input
//                   label="行動電話"
//                   value={values.member.mobile_phone}
//                   type="text"
//                   name="member[mobile_phone]"
//                   onChange={handleChange}
//                   placeholder={initialValues.member.mobile_phone}
//                   errors={'member' in errors && errors.member.mobile_phone}
//                 ></Input>

//                 <Input
//                   label="電子郵件"
//                   value={values.member.email}
//                   type="text"
//                   name="member[email]"
//                   onChange={handleChange}
//                   placeholder={initialValues.member.email}
//                   errors={'member' in errors && errors.member.email}
//                 ></Input>

//                 <InputGroup label="通訊地址" name="town_id">
//                   <Row>
//                     <TownGrop
//                       value={values.member.town_id}
//                       onChange={setFieldValue}
//                       name="member[town_id]"
//                       isInvalid={'member' in errors && errors.member.town_id}
//                     ></TownGrop>
//                     <Col xs={12} className="mt-2">
//                       <Form.Control
//                         type="text"
//                         value={values.member.address}
//                         name="member[address]"
//                         onChange={(e) =>
//                           this.addressChnageHandler(e, handleChange)
//                         }
//                         placeholder={initialValues.member.address}
//                         isInvalid={'member' in errors && errors.member.address}
//                       ></Form.Control>
//                       <Form.Control.Feedback type="invalid">
//                         {'member' in errors && errors.member.address}
//                       </Form.Control.Feedback>
//                       <div className="d-block invalid-feedback">
//                         {'member' in errors && errors.member.town_id}
//                       </div>
//                       <div>
//                         <p className="text-primary">
//                           請務必正確填寫，且不得為郵政信箱。
//                         </p>
//                       </div>
//                     </Col>
//                   </Row>
//                 </InputGroup>
//                 <Row className="mt-5 mb-4">
//                   <Col xs={6} md={2} className="ml-auto">
//                     <Button
//                       variant="outline-gray-500"
//                       onClick={resetForm}
//                       block
//                     >
//                       {' '}
//                       取消{' '}
//                     </Button>
//                   </Col>
//                   <Col xs={6} md={2} className="mr-auto">
//                     <Button variant="primary" type="submit" block>
//                       {' '}
//                       完成{' '}
//                     </Button>
//                   </Col>
//                 </Row>
//               </Form>
//             )
//           }}
//         </Formik>
//         <MobileVerifies
//           id={this.state.varifyId}
//           phone={this.state.varifyPhone}
//           trigger={this.state.varifyPopupTrigger}
//           prefix={this.state.varifyPrefix}
//           onClose={(e) => this.varifyPopupCloseHandler(e)}
//           params={this.state.submitData}
//         ></MobileVerifies>
//       </div>
//     )
//   }
// }

export default Info
