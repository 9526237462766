import React, { useState } from 'react';
import {
  Input,
  MyInputGroup as InputGroup,
  Selector,
} from '@/components/_common/FormInput';

function BirthdayInput({
  label, value, name, onChange, placeholder, errors, As = null,
}) {
  const [mask, setMask] = useState([/[1-2]/, /(0|9)/, /[0-9]/, /[0-9]/, /-/, /[0-1]/, /[0-9]/, /-/, /[0-3]/, /[0-9]/]);

  const handelMaskedValueChange = (newState, oldState) => {
    const { value, selection } = newState;
    const tmpMask = [...mask];
    if (selection != null && selection.end != null) {
      let { end } = selection;
      if (end == 5 || end == 8) {
        end -= 1;
      }

      // 日
      if (selection.end == 9) {
        const Tens = value[8];
        Tens == 3
          ? (tmpMask[9] = /(0|1)/)
          : Tens == 0
            ? (tmpMask[9] = /[1-9]/)
            : (tmpMask[9] = /[0-9]/);
      }
      // 月
      if (selection.end == 6) {
        const Tens = value[5];
        Tens == 1 ? (tmpMask[6] = /[0-2]/) : (tmpMask[6] = /[1-9]/);
      }
      // 年 百位數
      if (selection.end == 1) {
        const th = value[0];
        th == 2 ? (tmpMask[1] = /0/) : (tmpMask[1] = /9/);
      }
      if (!RegExp(tmpMask[end - 1]).test(value[end - 1])) {
        return oldState;
      }
    }

    return newState;
  };

  if (As) {
    return (
      <As
        label={label}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        error={errors}
        mask="9999-99-99"
        maskChar=" "
        beforeMaskedValueChange={handelMaskedValueChange}
      />
    );
  }

  return (
    <Input
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      errors={errors}
      mask="9999-99-99"
      maskChar=" "
      beforeMaskedValueChange={handelMaskedValueChange}
    />
  );
}

export default BirthdayInput;
