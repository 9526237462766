import { useField } from 'formik';
import * as React from 'react'
import { FC } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { SelectProps } from './types/inputProps';

const renderOptionsByArray = (options) => options.map(option => {
  if (option.constructor === Array) {
    return <option key={option[1]} value={option[1]}>{option[0]}</option>
  }
  return <option key={option} value={option}>{option}</option>
})

const renderOptions = (options: SelectProps['options']) => {
  // 1 [[key, value],[key, value]]
  // 2 {groupName:[[key, value], [key, value]]}
  // 3 {key: name, key: name}
  if (!options) return null
  if (options.constructor === Array) {
    return renderOptionsByArray(options)
  }

  return Object.keys(options).map(key => {
    if (options[key].constructor === Array) return (
      <optgroup label={key} key={key}>
        {renderOptionsByArray(options[key])}
      </optgroup>)
    return <option key={key} value={key}>{options[key]}</option>;

  })
};

const Select: FC<SelectProps> = ({
  disabled, label, options, name, info, size, defaultOption, settings,
}) => {

  const [{ onBlur, onChange, value }, { error, touched }] = useField<string | number>(name);

  console.log(value || "")

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>{label || settings?.title}</Form.Label>
      </Col>
      <Col sm={8} md={size == 'small' ? 4 : 6}>
        <Form.Control
          as="select"
          value={value || ""}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          isInvalid={touched && !!error}
        >
          {defaultOption && <option value="">請選擇</option>}
          {renderOptions(options || settings?.options)}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

Select.defaultProps = {
  label: '',
  info: '',
  disabled: false,
  options: null,
  settings: null,
  size: 'default',
  defaultOption: false,
};;

export default Select;
