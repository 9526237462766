import * as React from 'react';
import BasicInfo from './BasicInfo';
import InsuredForm from '../components/subForm/InsuredForm';
import KycsForm from '../components/subForm/KycsForm';
import BeneficiariesForm from '../components/subForm/BeneficiariesForm';

const VehicleForm = () => (
  <>
    <BasicInfo />
    <InsuredForm />
    <KycsForm />
    <BeneficiariesForm />
  </>
);

export default VehicleForm;
