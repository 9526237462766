function getValueByKeyString<T>(obj: any, keyString: T): T {
  if (keyString === null || keyString === undefined) return null;
  if (typeof keyString !== 'string') throw Error(`getValueByKeyString: should givien a string, you pass ${keyString}`);
  const keys = keyString.split(/\.|\u005d\u005b|[\u005d\u005b]/).filter((key) => key !== '');
  return keys.reduce((acc, key) => {
    if (acc) {
      return acc[key];
    }
    return null;
  }, obj);
};

export default getValueByKeyString;
