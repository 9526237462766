import * as React from 'react';
import * as moment from 'moment';
import { useFormikContext } from 'formik';
import { Input, DatePicker } from '../../components/formObjects';
import { VaccineInitialValues } from '../../policy';
import usePolicySetting from '../hooks/usePolicySetting';
import Flatpickr from "react-flatpickr";

const options = {
  enableTime: false,
  dateFormat: 'Y/m/d',
  // @ts-ignore
  minDate: new Date().fp_incr(5),
  // @ts-ignore
  maxDate: new Date().fp_incr(90),
};

function BasicInfo() {
  const { values, setFieldValue } = useFormikContext<VaccineInitialValues>();
  const { policy } = values;
  const { renew_insurance } = usePolicySetting("information", "vaccine")

  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">投保資料</h2>
      </div>

      <DatePicker
        options={{ defaultDate: policy.insured_from, ...options }}
        name="policy[insured_from]"
        label="生效日期"
      />
      {/* <Input value={moment(policy.insured_till).format('YYYY/MM/DD')} name="policy[insured_till]" label="失效日期" disabled disableFormik /> */}
    </div>
  );
}

export default BasicInfo;
