import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';

const fetchByCountry = (id) => fetch(`/api/v2/countries/get_options_by_country/${id}`).then((res) => res.json());
const fetchByContinent = (id) => fetch(`/api/v2/countries/get_options_by_content/${id}`).then((res) => res.json());
const fieldName = (index) => `policy[countries_policies][${index}][country_id]`;

function CountrySubForm({
  value, index, continentOptions, onRemove, onCreate,
}) {
  const [countries, setCountries] = useState([]);
  const [continentValue, setContinentValue] = useState(continentOptions[0][1]);
  const [shouldUpdateCountryOptions, setShouldUpdateCountryOptions] = useState(false);
  const {
    handleChange, handleBlur, setFieldValue, values,
  } = useFormikContext();

  useEffect(() => {
    if (value) {
      fetchByCountry(value).then((res) => {
        setCountries(res.countries);
        setContinentValue(res.continent_id);
      });
    } else {
      fetchByContinent(continentOptions[0][1]).then((res) => {
        setCountries(res.countries);
        setFieldValue(fieldName(index), res.countries[0][1]);
      });
    }
  }, []);

  useEffect(() => {
    if (shouldUpdateCountryOptions) {
      fetchByContinent(continentValue).then((res) => {
        setCountries(res.countries);
        setFieldValue(fieldName(index), res.countries[0][1]);
        setShouldUpdateCountryOptions(false);
      });
    }
  }, [shouldUpdateCountryOptions]);

  const handleContinentChange = (e) => {
    setContinentValue(e.target.value);
    setShouldUpdateCountryOptions(true);
  };

  const handleRemove = (e) => {
    setFieldValue(`policy[countries_policies][${index}][_destroy]`, true);
  };

  return (
    <>
      <Row className={index !== 0 ? 'mt-3' : ''}>
        <Col xs={6} md={4}>
          <Form.Control
            as="select"
            value={continentValue || ''}
            onChange={handleContinentChange}
          >
            {continentOptions.map((con) => (<option key={con[1]} value={con[1]}>{con[0]}</option>))}
          </Form.Control>
        </Col>
        <Col xs={6} md={4}>
          <Form.Control
            as="select"
            value={value || ''}
            name={fieldName(index)}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {countries.map((country) => (<option key={country[1]} value={country[1]}>{country[0]}</option>))}
          </Form.Control>
        </Col>
        <Col xs={12} md={4} className="text-xs-right">
          {index === 0 ? (
            <Button variant="link" onClick={() => onCreate({ country_id: null })}>
              <i className="fa fa-plus" aria-hidden="true" />
              新增地區
            </Button>
          ) : (
            <Button variant="link" onClick={handleRemove}>
              <i className="fa fa-minus" aria-hidden="true" />
              移除地區
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default CountrySubForm;
