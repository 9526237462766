import * as React from 'react';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore  
import policySettings from '@config/policy_setting.yml';
import Boolean from '../../components/formObjects/Boolean';
import Select from '../../components/formObjects/Select';
import Checkbox from '../../components/formObjects/Checkbox';
import Input from '../../components/formObjects/Input';
import CountriesForm from '../../components/formObjects/CountriesForm';
import PolicyContext from '../../components/PolicyContext';
import { TravelInitialValues, IPolicyNewContext } from '../../policy';

const { travel: travelInfoSettings } = policySettings.default.information;

const InformationForm = () => {
  const {
    activities, continents, abroad, requiredInformations,
  } = useContext(PolicyContext as React.Context<IPolicyNewContext>);
  const { values } = useFormikContext<TravelInitialValues>();
  return (
    <div>

      {abroad ? <CountriesForm options={continents} /> : null}
      {requiredInformations.map((key) => {
        const {
          title, input_type: type, options, placeholder,
        } = travelInfoSettings[key];
        if (key == 'activity_name') return <Select key={key} name="policy[information][activity_name]" options={activities || []} defaultOption />;
        switch (type) {
          case 'bool':
            return <Boolean key={key} name={`policy[information][${key}]`} inline settings={travelInfoSettings[key]} />;
          case 'select':
            return <Select key={key} name={`policy[information][${key}]`} defaultOption settings={travelInfoSettings[key]} />;
          case 'checkbox':
            return <Checkbox key={key} name={`policy[information][${key}]`} settings={travelInfoSettings[key]} />;
          case 'text':
            return <Input key={key} name={`policy[information][${key}]`} settings={travelInfoSettings[key]} />;
          case 'date':
            return <Input key={key} name={`policy[information][${key}]`} settings={travelInfoSettings[key]} />;
          case 'hidden':
            return <input type="hidden" name={`policy[information][${key}]`} value={values.policy.information?.[key]} />;
          default:
            return null;
        }
      })}
    </div>
  );
}

export default InformationForm;
