/* eslint-disable no-underscore-dangle */
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CountrySubForm from './CountrySubForm';

function CountriesForm({ options }) {
  const { values } = useFormikContext();
  const countries = values?.policy?.countries_policies || [];

  return (
    <Form.Group as={Row} controlId="policy[countries_policies]" className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>旅遊地區</Form.Label>
      </Col>
      <FieldArray
        name="policy[countries_policies]"
        render={({ remove, push }) => (
          <Col sm={8} md={9}>
            {countries.map((country, i) => (!country?._destroy ? (
              <CountrySubForm key={i.toString()} value={country?.country_id} index={i} continentOptions={options} onRemove={remove} onCreate={push} />
            ) : null))}
          </Col>
        )}
      />
      {/* <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info}</Form.Text>
      </Col> */}
    </Form.Group>
  );
}

export default CountriesForm;
