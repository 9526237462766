import * as React from 'react';
import {
  useContext, useEffect, useState,
} from 'react';
import { FormikContext, useFormikContext } from 'formik';
// @ts-ignore
import policySettings from '@config/policy_setting.yml';
import {
  Input, DatePicker, Radio, Select, File,
} from '../../components/formObjects';
import 'flatpickr/dist/themes/material_green.css';
import PolicyContext from '../../components/PolicyContext';
import getValueByKeyString from '../../components/formObjects/getValueByKeyString';
import { DateTimePickerProps } from 'react-flatpickr';
import { IInitialValues, IPolicyNewContext, PetInitialValues } from '../../policy';
import usePolicySetting from '../hooks/usePolicySetting';

const extraDay = new Date().getHours() >= 23 ? 1 : 0;

const tagNameAttr = (key) => `policy[information][${key}]`;


function BasicInfo() {
  const { values } = useFormikContext<PetInitialValues>();
  const { policy } = values;
  const [dateOptions, setDateOptions] = useState<DateTimePickerProps["options"]>();
  const { requiredInformations, pet_race: petRaceOptions } = useContext(PolicyContext as React.Context<IPolicyNewContext>);
  const petInfoSettings = usePolicySetting("information", "pet")
  useEffect(() => {
    setDateOptions({
      enableTime: false,
      dateFormat: 'Y/m/d',
      // @ts-ignore
      minDate: new Date().fp_incr(1 + extraDay),
      // @ts-ignore
      maxDate: new Date().fp_incr(30 + extraDay),
    });
  }, []);

  const renderFileInput = () => {
    switch (policy.information.certified_document) {
      case 'etag':
        return (<Input name={tagNameAttr('etag_serial_number')} settings={petInfoSettings.etag_serial_number} />);
      case 'blood':
        return (<File name={tagNameAttr('document_file')} label="血統證明" settings={petInfoSettings.document_file} />);
      case 'exam':
        return (<File name={tagNameAttr('document_file')} label="體檢證明" settings={petInfoSettings.document_file} />);
      case 'injection':
        return (<File name={tagNameAttr('document_file')} label="注射證明" settings={petInfoSettings.document_file} />);
      default:
        return null;
    }
  };

  const { handleChange } = useFormikContext<IInitialValues<any>>();

  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">投保資料</h2>
      </div>
      <DatePicker
        options={{ defaultDate: policy.insured_from, ...dateOptions }}
        name="policy[insured_from]"
        label="生效日期"
      />
      {requiredInformations.includes('pet_name') && <Input name={tagNameAttr('pet_name')} settings={petInfoSettings.pet_name} />}
      {requiredInformations.includes('pet_kind') && <Radio name={tagNameAttr('pet_kind')} settings={petInfoSettings.pet_kind} disabled />}
      {requiredInformations.includes('pet_birthday') && <Input name={tagNameAttr('pet_birthday')} settings={petInfoSettings.pet_birthday} disabled />}
      {requiredInformations.includes('pet_race') && <Select name={tagNameAttr('pet_race')} settings={petInfoSettings.pet_race} options={petRaceOptions} defaultOption />}
      {requiredInformations.includes('pet_gender') && <Radio name={tagNameAttr('pet_gender')} settings={petInfoSettings.pet_gender} />}
      {requiredInformations.includes('pet_weight') && <Input name={tagNameAttr('pet_weight')} settings={petInfoSettings.pet_weight} />}
      {requiredInformations.includes('pet_color') && <Input name={tagNameAttr('pet_color')} settings={petInfoSettings.pet_color} />}
      {requiredInformations.includes('certified_document') && (
        <>
          <Select
            name={tagNameAttr('certified_document')}
            settings={petInfoSettings.certified_document}
            options={[['晶片序號', 'etag'], ['血統證明', 'blood'], ['體檢證明', 'exam'], ['注射證明', 'injection']]}
            defaultOption
          />
          {renderFileInput()}
        </>
      )}
      {requiredInformations.includes('etag_serial_number') && <Input name={tagNameAttr('etag_serial_number')} settings={petInfoSettings.etag_serial_number} />}
      {requiredInformations.includes('document_file') && <File name={'policy[information][document_file]'} settings={petInfoSettings.document_file} accept=".png,.jpg,.jpeg,.bmp" />}
      {requiredInformations.includes('picture_file') && <File name={'policy[information][picture_file]'} settings={petInfoSettings.picture_file} accept=".png,.jpg,.jpeg,.bmp" />}
    </div>
  );
}

export default BasicInfo;
