import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useField } from 'formik';
import * as moment from 'moment';
import { InputProps } from './types/inputProps';
import styled from 'styled-components';

const Input: React.FC<InputProps> = ({
  disabled, label, placeholder, name, info, settings, type, value: v, disableFormik, onBlur, onChange, right
}) => {

  const [inputProps, metaProps] = useField<string>(name);

  const [formatValue, setFormatValue] = useState('');

  useEffect(() => {
    if (!isNaN(Date.parse(inputProps.value))) {
      if (type == 'date') setFormatValue(moment(inputProps.value).format('YYYY/MM/DD'))
      else if (type == 'dateTime') setFormatValue(moment(inputProps.value).format('YYYY/MM/DD HH:mm'))
    }
  }, [inputProps.value])

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>{label || settings?.title}</Form.Label>
      </Col>
      <Col sm={8} md={6}>
        <Group>
          {disableFormik
            ? (<Form.Control
              value={v || ''}
              name={name}
              type="text"
              placeholder={placeholder || settings?.placeholder}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              isInvalid={metaProps.touched && !!metaProps.error}
            />)
            : (<Form.Control
              value={inputProps.value || ''}
              name={name}
              type="text"
              placeholder={placeholder || settings?.placeholder}
              disabled={disabled}
              onChange={inputProps.onChange}
              onBlur={inputProps.onBlur}
              isInvalid={metaProps.touched && !!metaProps.error}
            />)
          }
          {right && <Right>{right}</Right>}
          <Form.Control.Feedback type="invalid">{metaProps.error}</Form.Control.Feedback>
        </Group>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

Input.defaultProps = {
  disabled: false,
  label: '',
  info: '',
  placeholder: '',
  settings: null,
  type: 'text',
  disableFormik: false,
  value: null,
  right: null,
};

const Group = styled.div`
  position: relative;
`
const Right = styled.div`
  position: absolute;
  height: 38px;
  top: 0;
  line-height: 38px;
  right: 1px;
  background: none;
  border: none;
  padding: 0 10px 0;
  display: grid;
`

export default Input;
