import * as React from 'react';
import { useState, FC } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import useFormikInputProps from './useFormikInputProps';
import toArray from './toArray';
import { RadioProps } from './types/inputProps';
import { useField } from 'formik';





const Radio: FC<RadioProps> = ({
  label, name, options, info, size, settings, disabled
}) => {

  const [{ onBlur, onChange, value }, { error, touched }] = useField<string | number>(name);
  const handleClickAndBlur = (e) => {
    onBlur(e);
    onChange(e);
  };

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col sm={4} md={size == 'small' ? 4 : 2}>
        <Form.Label>{label || settings?.title}</Form.Label>
      </Col>
      <Col sm={8} md={6}>
        {((options && options.length > 0 && options) || settings?.options || []).map((option => (
          <Form.Check
            key={option[1]}
            inline
            type="radio"
            id={`${name}_${option[1]}`}
            name={name}
            label={option[0]}
            value={option[1]}
            onChange={handleClickAndBlur}
            checked={value == option[1]}
            isInvalid={touched && !!error}
            disabled={disabled}
          />
        )))}
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Control type="hidden" disabled value={value} name={name} isInvalid={touched && !!error} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

Radio.defaultProps = {
  label: '',
  info: '',
  options: [],
  settings: null,
  size: 'default',
  disabled: false,
};;

export default Radio;
