/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MyPopup } from '@/components/_common/StyledPopup';
import PolicyContext from '../../components/PolicyContext';
import { PolicyEditContext } from '../../policy';

const scrollbarStyle = { height: '100%', minHeight: '400px' }
const scrollbarContentStyle = { marginRight: '10px' }
function PersonalCapitalRead() {
  const { popup } = useContext(PolicyContext as React.Context<PolicyEditContext>);
  return (
    <MyPopup
      trigger={<a className="text-primary h5">個人資料告知與同意事項聲明書</a>}
      modal
      size="lg"
    >
      <div>
        <h5 className="title-line-secondary">個人資料告知與同意事項聲明書</h5>
      </div>
      <Scrollbars style={scrollbarStyle}>
        <div className="text-gray" style={scrollbarContentStyle} dangerouslySetInnerHTML={{ __html: popup.personalCapital }} />
      </Scrollbars>
    </MyPopup>
  );
}

export default PersonalCapitalRead;
