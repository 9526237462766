export default () => {
  const watcher = new IntersectionObserver(onEnterView)
  const lazyImages = document.querySelectorAll('img[loading="lazy"]')
  const lazyBgImages = document.querySelectorAll('.lazy-bg')
  for (let image of lazyImages) {
    watcher.observe(image) // 開始監視
  }
  for (let image of lazyBgImages) {
    watcher.observe(image) // 開始監視
  }

  function onEnterView(entries, observer) {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        // 監視目標進入畫面
        const img = entry.target
        if (img.classList.contains("lazy-bg")) {
          img.setAttribute('style', `background-image: url(${img.dataset.bgsrc})`) // 把值塞回 src  
        } else {
          img.setAttribute('src', img.dataset.src) // 把值塞回 src
        }
        img.removeAttribute('data-src')
        img.removeAttribute('data-bgsrc')
        observer.unobserve(img) // 取消監視
      }
    }
  }
};