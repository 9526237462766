import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

function RouteWithSubRoutes({ routes }) {
  return (
    <Switch>
      {routes && routes.map((route) => (
        <Route
          path={route.path}
          exact={route.exact || false}
          key={route.path}
          render={(props) => (
            <route.component {...props} {...route.props} routes={route.routes} />
          )}
        />
      ))}
    </Switch>
  );
}

RouteWithSubRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RouteWithSubRoutes;
