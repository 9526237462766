import * as React from 'react';
import {
  Form, Col, Row,
} from 'react-bootstrap';
import { useFormikContext, useField } from 'formik';
import TownGrop from 'components/Member/common/TownGrop';
import Input from '../../../components/formObjects/Input';
import { IInitialValues } from '../../../policy';
import useOccupation from '../../hooks/useOccupation';


function OccupationForm() {
  const [{ value, onBlur }, { touched, error }, { setTouched, setValue, setError }] = useField(`policy[insured][occupation_code]`);
  const { code, options, values, handleCodeChange } = useOccupation(value, setValue)




  return (
    <Form.Group as={Row} className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>職業</Form.Label>
      </Col>
      <Col sm={8} md={6} as={Row} className="p-0 m-0">
        <Form.Group as={Col}>
          <Form.Control
            as="select"
            value={values.type}
            onChange={handleCodeChange}
          >
            <option value="">請選擇</option>
            {options && options.types.map(type => <option key={type[1]} value={type[1]}>{type[0]}</option>)}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Control
            as="select"
            value={values.class}
            onChange={handleCodeChange}
          >
            <option value="">請選擇</option>
            {options && options.classes.map(c => <option value={c[1]} key={c[1]}>{c[0]}</option>)}
          </Form.Control>
        </Form.Group >
        <Form.Group as={Col} xs={12}>
          <Form.Control
            as="select"
            value={values.occupation}
            onChange={handleCodeChange}
            onBlur={() => setTouched(true)}
            isInvalid={touched && !!error}
            name="policy[insured][occupation_code]"
          >
            <option value="">請選擇</option>
            {options && options.occupations.map(occupation => <option value={occupation[1]} key={occupation[1]}>{occupation[0]}</option>)}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group >


      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{error}</Form.Text>
      </Col>
    </Form.Group>
  );
}

export default OccupationForm;
