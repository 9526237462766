export default () => {

  function prevent(event) {
    event.preventDefault();
    console.log("prevent")
  }

  $('a[href="###"]').click(prevent)
  $('body').off("click", "a[href!='###']", prevent)



}