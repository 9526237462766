import * as yup from "yup";

const salesNumberSchema = (setInfo: React.Dispatch<React.SetStateAction<string>>, memberId: number) => yup.string()
  .nullable()
  .min(4, '格式錯誤')
  .matches(/[A-Z][0-9]/, '請輸入英數字組合')
  .test(function (value: string) {
    return fetch('/verify_sales_numbers', {
      method: 'POST',
      body: JSON.stringify({ eno: value, member_id: memberId }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(res => {
        if (res.error_message) {
          setInfo('')
          return this.createError({ message: res.error_message })
        }
        setInfo(res.name)
        return true
      })
      .catch(() => {
        setInfo('')
        return this.createError({ message: '網路錯誤，請稍後再試' })
      })
  })

export default salesNumberSchema
