import { useField } from 'formik';
import * as React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';

const LICENSE_FIRST = 'policy[information][license_plate_first]'
const LICENSE_LAST = 'policy[information][license_plate_last]'

const LicensePlateInput = () => {

  const [firstInputProps, firstMetaProps] = useField<string>(LICENSE_FIRST);
  const [lastInputProps, lastMetaProps] = useField<string>(LICENSE_LAST);

  return (
    <Form.Group as={Row} controlId="license_plate" className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>牌照號碼</Form.Label>
      </Col>
      <ColWrapper sm={8} md={6}>
        <Col className="col-license">
          <Form.Control
            value={firstInputProps.value}
            name={LICENSE_FIRST}
            type="text"
            onChange={firstInputProps.onChange}
            onBlur={firstInputProps.onBlur}
            isInvalid={firstMetaProps.touched && lastMetaProps.touched && !!lastMetaProps.error}
          />
        </Col>
        <Col xs={1}>
          <p>-</p>
        </Col>
        <Col className="col-license">
          <Form.Control
            value={lastInputProps.value}
            name={LICENSE_LAST}
            type="text"
            onChange={firstInputProps.onChange}
            onBlur={firstInputProps.onBlur}
            isInvalid={lastMetaProps.touched && firstMetaProps.touched && !!lastMetaProps.error}
          />
        </Col>
      </ColWrapper>
      {lastMetaProps.touched && firstMetaProps.touched && lastMetaProps.error && (
        <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
          <Form.Text className="text-error">{lastMetaProps.error}</Form.Text>
        </Col>
      )}
    </Form.Group>
  )
}

const ColWrapper = styled(Col)`
  display: flex;
  padding: 0;
  .col-license  {
    flex: 1;
    width: auto;
    max-width: 100%;

  }
`;

export default LicensePlateInput
