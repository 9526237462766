import React from 'react'
import themes from '@/components/Themes'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import routes from './routes'
import RouteWithSubRoutes from './components/RouteWithSubRoute'
import MemberContext from '@/components/_context/MemberContext'


function Member({ currentMember }) {
  return (
    <ThemeProvider theme={ themes }>
      <MemberContext.Provider value={{ currentMember }}>
        <Router>
          <RouteWithSubRoutes routes={routes} />
        </Router>
      </MemberContext.Provider>
    </ThemeProvider>
  )
}

export default Member;