import { useField } from 'formik';
import * as React from 'react';
import { useState, FC } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import toArray from './toArray';
import { CheckBoxProps } from './types/inputProps';

const CheckBox: FC<CheckBoxProps> = ({
  label, name, options, info, settings, disabled,
}) => {

  const [{ onBlur, onChange, value }, { error, touched }, { setValue }] = useField(name);

  const handleClickAndBlur = (e) => {
    onBlur(e);
    onChange(e);
  };
  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col xs={12}>
        <Form.Label>{label || settings?.title}</Form.Label>
      </Col>
      <Col xs={12}>
        {((options?.length > 0 && options) || settings?.options || []).map((option) => (
          <Form.Check
            key={option[1]}
            inline
            type="checkbox"
            id={`${name}-${option[1]}`}
            name={name}
            label={option[0]}
            value={option[1]}
            onChange={handleClickAndBlur}
            checked={!!value && value.includes(option[1].toString())}
            disabled={disabled}
          />
        ))}
      </Col>
      <Col xs={12}>
        <Form.Control type="hidden" disabled value={value || ''} name={name} isInvalid={touched && !!error} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

CheckBox.defaultProps = {
  label: '',
  info: '',
  options: null,
  settings: null,
  disabled: false,
};;

export default CheckBox;
