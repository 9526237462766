import React, { useEffect } from 'react'
import styled from 'styled-components';
import { ReactComponent as ThankyouIcon } from '../../images/index/service-icon-left.svg'

const Container = styled.div`
  height: calc(100vh - 187px);
  display: flex;
  justify-content: center;
  align-items: center;
`

function ThankyouPage() {

  useEffect(() => {
    document.title = "感謝您｜SARAcares莎拉保險網"
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': '/form/inquiry/thankyou',
      'pageTitle': '感謝你 | SARAcares莎拉保險網'
    });
    window.dataLayer.push({
      'event': 'virtualPageview',
      'pageUrl': 'https://www.saracares.com.tw/form/inquiry/thankyou',
      'pageTitle': '感謝你 | SARAcares莎拉保險網'
    });
  }, [])

  return (
    <Container>
      <div>
        <ThankyouIcon maxHeight="220px" className="mr-3" />
        <h4 className="mt-3 text-center">謝謝你的填寫，我會盡快與你聯繫：）</h4>
      </div>
    </Container>
  )
}

export default ThankyouPage