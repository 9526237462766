/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { MyPopup } from 'components/_common/StyledPopup';
import { Term, Box } from 'components/Member/Policies/PolicyShow/Contents';

const termsApi = '/packages/terms/';

interface IProps {
  packageId: number | string
}

const ContractRead: FC<IProps> = ({ packageId }) => {
  const [terms, setTerms] = useState([]);
  useEffect(() => {
    if (packageId) {
      fetch(`${termsApi}?id=${packageId}`)
        .then((res) => res.json())
        .then((res) => {
          setTerms(res || []);
        })
        .catch((err) => { });
    }
  }, [packageId]);
  return (
    <MyPopup
      trigger={<a className="text-primary h5">條款、備註及注意事項</a>}
      modal
    >
      <div>
        <h4 className="text-center">保單條款</h4>
        <Box>
          {terms.map((term, index) => (
            <Term key={term.id} term={term} index={index} />
          ))}
        </Box>
      </div>
    </MyPopup>
  );
}

export default ContractRead;
