import * as React from 'react';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore
import policySettings from '@config/policy_setting.yml';
import {
  Boolean, Select, Checkbox, Input, Radio,
} from '../../components/formObjects';
import PolicyContext from '../../components/PolicyContext';
import { IPolicyNewContext, PetInitialValues } from '../../policy';

const { pet: petInfoSettings } = policySettings.default.information;

function InformationForm() {
  const {
    requiredInformations,
  } = useContext(PolicyContext as React.Context<IPolicyNewContext>);
  const { values } = useFormikContext<PetInitialValues>();
  return (
    <div>
      {requiredInformations.map((key) => {
        const {
          input_type: type, options, placeholder,
        } = petInfoSettings[key];
        switch (type) {
          case 'bool':
            return <Boolean key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} inline />;
          case 'select':
            return <Select key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} options={options && Object.keys(options).map((k) => [options[k], k])} defaultOption />;
          case 'checkbox':
            return <Checkbox key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} options={options && Object.keys(options).map((k) => [options[k], k])} />;
          case 'text':
            return <Input key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} placeholder={placeholder} />;
          case 'date':
            return <Input key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} placeholder={placeholder} />;
          case 'radio':
            return <Radio key={key} settings={petInfoSettings[key]} name={`policy[information][${key}]`} options={options && Object.keys(options).map((k) => [options[k], k])} />;
          case 'hidden':
            return <input type="hidden" name={`policy[information][${key}]`} value={values.policy.information?.[key]} />;
          default:
            return null;
        }
      })}
    </div>
  );
}

export default InformationForm;
