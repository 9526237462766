import * as React from 'react';
import { useContext } from 'react'
import { useFormik, useFormikContext } from 'formik';
import { VehicleInitialValues, PolicyNewContext, VehicleInformation, Member } from '../../policy';
import LicensePlateInput from './components/LicensePlateInput';
import { Select, Input, Boolean, Checkbox } from '../../components/formObjects/index';
import PolicyContext from '../../components/PolicyContext';
import CarBrandInput from './components/CarBrandInput';
import DatePicker from '../../components/formObjects/DatePicker';
import useInsuredStartDate from '../../../../hooks/useInsuredStartDate';
import useInsuredMaxStartDate from '../../../../hooks/useInsuredMaxStartDate';
import YearAndDateInput from './components/YearAndDateInput';
import usePolicySetting from '../hooks/usePolicySetting';
import InformationForm from './InformationForm';


function BasicInfo() {
  const { values, setFieldValue } = useFormikContext<VehicleInitialValues>();
  const carInfoSettings = usePolicySetting("information", "car")
  const { policy } = values;
  const { car_kinds: carKinds, requiredInformations, has_compulsory_insurance, has_voluntary_insurance } = useContext(PolicyContext as React.Context<PolicyNewContext<VehicleInformation>>);
  const insuredFromDateMinLimit = useInsuredStartDate()
  const insuredFromDateMaxLimit = useInsuredMaxStartDate(3, "M")
  const insuredFromDateOptions = {
    enableTime: false,
    dateFormat: 'Y/m/d',
    minDate: insuredFromDateMinLimit,
    maxDate: insuredFromDateMaxLimit
  }

  const dateLicenseOptions = {
    enableTime: false,
    dateFormat: 'Y/m/d',
    maxDate: new Date()
  }

  React.useEffect(() => {
    setFieldValue('policy[information][insured_citizen_id]', values.policy.insured.citizen_id)
    if (requiredInformations.includes("is_business_vehicle")) {
      setFieldValue('policy[information][is_business_vehicle]', true)
    }
  }, [])

  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">車籍資料</h2>
      </div>
      <input hidden type="text" name="policy[information][type]" value="car" />
      <Input name="policy[information][citizen_id]" settings={carInfoSettings.citizen_id} />
      <LicensePlateInput />
      <Select name="policy[information][car_kind]" settings={carInfoSettings.car_kind} options={carKinds} defaultOption={true} />
      <CarBrandInput vehicleType={policy.policy_type} />
      <Input name="policy[information][car_exhaust]" settings={carInfoSettings.car_exhaust} right="c.c./p.h" />
      <Input name="policy[information][engine_num]" settings={carInfoSettings.engine_num} />
      <Select name="policy[information][passengers_number]" settings={carInfoSettings.passengers_number} defaultOption />
      <YearAndDateInput name="policy[information][date_car_made]" label="出廠年月" />
      <InformationForm /> 
      {requiredInformations.includes("date_license") && <DatePicker
        options={{ defaultDate: policy.information?.date_license || "", ...dateLicenseOptions }}
        name="policy[information][date_license]"
        label="原行照發行日"
      />}
      {requiredInformations.includes("date_compulsory_insurance") && <DatePicker
        options={{ defaultDate: policy.insured_from, ...insuredFromDateOptions }}
        name="policy[insured_from]"
        label="強制險生效日"
      />}

      {requiredInformations.includes("date_voluntary_insurance") && <DatePicker
        options={{ defaultDate: policy.insured_from2, ...insuredFromDateOptions }}
        name="policy[insured_from2]"
        label="任意險生效日"
      />}
    </div>
  );
}

export default BasicInfo;
