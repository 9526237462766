//for rails
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';
import $ from 'jquery'
import 'jquery-mousewheel'
import ScrollBar from 'smooth-scrollbar'

//basic import
import preventDefault from "units/hashTagPreventDefault"
import checkBrowser from "units/checkIE"
import lazyLoadFont from "units/lazyLoadFont"
import lazyload from "units/lazyload"
require.context('@/images', true);
//bootstrap
import "stylesheets/bootstrap/bootstrap.scss";
import 'bootstrap/dist/js/bootstrap';

//swiper
import Swiper, {
  Navigation,
  Pagination
} from 'swiper';
import 'swiper/swiper-bundle.min.css';


//form validate
import 'jquery-validation';


//light box
import "magnific-popup/dist/magnific-popup.css";

//lazyload
//import "jquery-lazy"
console.log()
Rails.start();
//Turbolinks.start();
ActiveStorage.start();
window.jQuery = $
window.$ = $
window.ScrollBar = ScrollBar
Swiper.use([Navigation, Pagination])
window.Swiper = Swiper;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require.context('../images', true)
require.context('../video', true)

$(document).ready(() => {
  loadMagnificPopup()
  preventDefault();
  lazyLoadFont();
  lazyload();
  loadFontAwesome();

  //$(".lazy").lazy();
  //$("img").lazy();
  if (checkBrowser.isIE() || checkBrowser.isEdge()) {
    window.location.replace('#borwser');
  }

  window.addEventListener('mousewheel', initFB)

  document.querySelectorAll(".scroll_bar").forEach(element => {
    ScrollBar.init(element)
  })
})
const initFB = () => {
  loadFB(document, 'script', 'facebook-jssdk')
}
const loadFB = (d, s, id) => {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
  fjs.parentNode.insertBefore(js, fjs);
  window.removeEventListener("mousewheel", initFB)
}

// 給需要提供FB API的使用
window.loadFB = loadFB

const loadFontAwesome = async () => {
  await import('@fortawesome/fontawesome-free/css/fontawesome')
  await import('@fortawesome/fontawesome-free/css/brands')
  await import('@fortawesome/fontawesome-free/css/solid')
  await import('@fortawesome/fontawesome-free/css/regular')
  await import('@fortawesome/fontawesome-free/js/fontawesome')
};

const loadMagnificPopup = async () => {
  await import("magnific-popup")
  const {
    default: magnificPopupPlugin
  } = await import("units/magnificPopupByBtn")
  magnificPopupPlugin.lightbox();
  magnificPopupPlugin.modal();
  $(document).trigger("MagnificPopupReady")
  if ($("#announcement").length) {
    $(document).ready(function () {
      $.magnificPopup.open({
        items: {
          src: '#announcement',
        },
        type: 'inline'
      });
    })
  }
};

console.log('load application.js')