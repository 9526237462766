import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, useFormik } from 'formik';
import {
  Row, Col, Form, Button, InputGroup,
} from 'react-bootstrap';
import qs from 'qs';
import Flex from '@/components/_common/Flex';
import { FormFeedback } from '@/components/_common/Inputs';
import jwtHeader from '@/components/_common/jwtHeader';
import StyledPopup from './StyledPopup';

const createOTPCode = (mobilePhone, resent = false) => fetch('/member/mobile_verifies', {
  method: 'POST',
  headers: jwtHeader(),
  body: JSON.stringify({
    mobile_verify: {
      phone_number: mobilePhone,
    },
    resend: resent,
  }),
})
  .then((res) => res.json())
  .then((res) => {
    if (res.code !== '00') throw new Error(res.error);
    return { id: res.id, prefix: res.prefix };
  });

const verifyOTPCode = (id, values) => {
  const params = qs.stringify(values);
  return fetch(`/member/mobile_verifies/${id}/edit?${params}`, { method: 'GET' })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) throw new Error(res.error);
      return true;
    });
};

let tid = null;

function OTPForm({ mobilePhone, onSuccess = () => { }, disabledClose = false }) {
  const [verification, setVerification] = useState(null);
  const [shouldResend, setShouldResend] = useState(false);
  const [tick, setTick] = useState(180);


  useEffect(() => {
    setTick(180);
    setShouldResend(false);
    if (verification) tid = setInterval(() => setTick((state) => state - 1), [1000]);
  }, [verification]);

  useEffect(() => {
    if (tick !== 0) return;
    clearInterval(tid);
    setShouldResend(true);
  }, [tick]);

  useEffect(() => {
    createOTPCode(mobilePhone)
      .then((res) => setVerification(res))
      .catch((err) => alert(err.message));
  }, []);

  const handleResend = () => {
    if (!shouldResend) {
      alert(`Ｏh! 時間還沒到喔！請於${tick}秒後再嘗試重送`);
    } else {
      createOTPCode(mobilePhone, true)
        .then((res) => setVerification(res))
        .catch((err) => alert(err.message));
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile_verify: {
        check_code: '',
      },
    },
    onSubmit: (values) => {
      verifyOTPCode(verification.id, values)
        .then(() => onSuccess())
        .catch((err) => alert(err.message));
    },
  });

  return (
    <StyledPopup noCloseBtn={disabledClose} modal open disable={disabledClose}>
      <div className="p-0 p-md-5 text-center">
        <p className="font-weight-normal">
          為確保是您本人投保，需要進行 OTP 驗證，請輸入驗證碼，
          <br />
          若
          <span className="text-primary">{tick}</span>
          秒內未收到請按重新傳送或確認手機號碼是否有誤。
        </p>
        <Form onSubmit={formik.handleSubmit}>
          <Row xs="auto">
            <Col xs={8} className=" pr-1">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {verification?.prefix || 'XXX'}
                    -
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="mobile_verify[check_code]"
                  value={formik.values.mobile_verify.check_code}
                  onChange={formik.handleChange}
                  placeholder="請輸入驗證碼"
                />
              </InputGroup>
            </Col>
            <Col xs={4} className="pl-1">
              <Button variant="primary" type="submit" block>
                確認
              </Button>
            </Col>
          </Row>
        </Form>
        <p className="mt-2">
          <a href="###" onClick={handleResend}>
            重新傳送驗證碼
          </a>
        </p>
      </div>
    </StyledPopup>

  );
}

// $('#mobile_verify_submit').click(function(update) {
//   update.preventDefault();
//   showLoading();
//   var checkId = $('.verificationcode__title').data('item');
//   var checkCode = $('#mobile_verify_check_code').val();

//   $.ajax({
//     url: "/member/mobile_verifies/" + checkId + "/edit",
//     method: "GET",
//     dataType: "json",
//     data: {
//       mobile_verify:{
//          check_code: checkCode
//       }
//     },
//     success: function(updateData){
//       hideLoading();
//       if (updateData.error) {
//         alert(updateData.error);
//         $('#mobile_verify_check_code').val('');
//       } else {
//         // $('#notification-btn').click();
//         post_to_url("#{@payment_url}", {
//           'idn': "#{@accident_policy.insureds.first.citizen_id}",
//           'txnAmount': "#{@accident_policy.fee}",
//           'applyNo': "#{@accident_policy.apply_number}",
//           'orderNo': "#{@accident_policy.order_no}",
//           'kind': "SARACARES",
//           'returnUrl': "#{Setting.domain}/member/accident_policy/completed?apply_number=#{@accident_policy.apply_number}"
//         });
//       }
//     }
//   });
// });

export default OTPForm;
