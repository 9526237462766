import * as React from 'react'
import { useEffect, useState } from 'react'
import { useField, useFormikContext } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import { useCarBrand, useCarBrandBack } from './hooks'

function CarBrandInput({ vehicleType }) {
  const [{ value, onBlur }, metaProps, inputHelper] = useField('policy[information][car_model]')
  const [origin, setOrigin] = useState('0')
  const [brand, setBrand] = useState('')
  const [carName, setCarName] = useState('')
  const { origins, brands, cars } = useCarBrand(origin, brand, vehicleType);

  const recoverData = useCarBrandBack(metaProps.initialValue, vehicleType);

  // set car model if car name is include cars from api
  useEffect(() => {
    if (carName && cars && cars.length) {
      const car = cars.find(c => c.name === carName);
      if (!!car) inputHelper.setValue(car.model)
      else if (carName) inputHelper.setError("輸入文字需與選單項目相同")
    }
  }, [carName])

  // initialize car brand
  useEffect(() => {
    if (brands && brands.length > 0) setBrand(brands[0].id.toString())
  }, [brands])

  useEffect(() => {
    if (!carName && value && recoverData?.car) {
      setCarName(recoverData.car.name)
      setOrigin(recoverData.origin.id.toString())
      setBrand(recoverData.brand.id.toString())
    }
  }, [value, recoverData])

  return (
    <>
      <Row className="my-3">
        <Col sm={4} md={2}>
          <Form.Label>廠牌</Form.Label>
        </Col>
        <Col sm={4} md={3}>
          <Form.Control
            as="select"
            value={origin}
            onChange={e => setOrigin(e.target.value.toString())}
          >
            <option value="">請選擇出產地</option>
            <option id="car_made_default" value="0">國產車</option>
            <option disabled value="">【進口車】</option>
            {origins && origins.map(origin => (
              <option key={origin.id} value={origin.id}>{origin.name}</option>
            ))}
          </Form.Control>
        </Col>
        <Col sm={4} md={3}>
          <Form.Control
            as="select"
            value={brand}
            onChange={e => setBrand(e.target.value.toString())}
          >
            {brands && brands.map(brand => (
              <option key={brand.id} value={brand.id}>{brand.name}</option>
            ))}
          </Form.Control>
        </Col>
      </Row>

      <Form.Group as={Row} className="my-3">
        <Col sm={4} md={2}>
          <Form.Label>車型</Form.Label>
        </Col>
        <Col sm={8} md={6}>
          <Form.Control
            list="cars"
            value={carName}
            onChange={e => setCarName(e.target.value)}
            onBlur={onBlur}
            isInvalid={metaProps.touched && !!metaProps.error}
            name="policy[information][car_model]"
          />
          <datalist id="cars">
            {cars && cars.map(car => (
              <option key={car.id} value={car.name} />
            ))}
          </datalist>
          {metaProps.touched && <Form.Text className="text-error">{metaProps.error}</Form.Text>}

        </Col>
      </Form.Group>
    </>
  )
}

export default CarBrandInput
