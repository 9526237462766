import * as React from "react";
import { useContext } from "react";
import { useFormikContext } from "formik";
// @ts-ignore
import policySettings from "@config/policy_setting.yml";
import Boolean from "../../components/formObjects/Boolean";
import Select from "../../components/formObjects/Select";
import Checkbox from "../../components/formObjects/Checkbox";
import Input from "../../components/formObjects/Input";
import CountriesForm from "../../components/formObjects/CountriesForm";
import PolicyContext from "../../components/PolicyContext";
import { TravelInitialValues, IPolicyNewContext } from "../../policy";

const { car: carInfoSetting } = policySettings.default.information;

const skipKeys = [
  "date_compulsory_insurance",
  "date_voluntary_insurance",
  "date_license",
  "citizen_id",
  "car_kind",
  "car_exhaust",
  "type",
  "engin_num",
  "passengers_number",
  "information",
  "date_license",
];

const InformationForm = () => {
  const { activities, continents, abroad, requiredInformations } = useContext(
    PolicyContext as React.Context<IPolicyNewContext>
  );
  const { values } = useFormikContext<TravelInitialValues>();
  return (
    <div>
      {abroad ? <CountriesForm options={continents} /> : null}
      {requiredInformations.map((key) => {
        const {
          title,
          input_type: type,
          options,
          placeholder,
        } = carInfoSetting[key];

        console.log({ key });

        if (skipKeys.includes(key)) return <></>;
        switch (type) {
          case "bool":
            return (
              <Boolean
                key={key}
                name={`policy[information][${key}]`}
                inline
                settings={carInfoSetting[key]}
              />
            );
          case "select":
            return (
              <Select
                key={key}
                name={`policy[information][${key}]`}
                defaultOption
                settings={carInfoSetting[key]}
              />
            );
          case "checkbox":
            return (
              <Checkbox
                key={key}
                name={`policy[information][${key}]`}
                settings={carInfoSetting[key]}
              />
            );
          case "text":
            return (
              <Input
                key={key}
                name={`policy[information][${key}]`}
                settings={carInfoSetting[key]}
              />
            );
          case "date":
            return (
              <Input
                key={key}
                name={`policy[information][${key}]`}
                settings={carInfoSetting[key]}
              />
            );
          case "hidden":
            return (
              <input
                type="hidden"
                name={`policy[information][${key}]`}
                value={values.policy.information?.[key]}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default InformationForm;
