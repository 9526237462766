import * as React from 'react';
import { useEffect } from 'react';
import {
  Form, Col, Row,
} from 'react-bootstrap';
import { useFormikContext } from 'formik';
import TownGrop from 'components/Member/common/TownGrop';
import { InitialValues, PolicyNewContext, Member, Information } from '../../../policy';
import OccupationForm from './OccupationForm';
import PolicyContext from '../../../components/PolicyContext';
import PureInput from '../../../components/formObjects/PureInput';
import { Radio, Input, DateInput } from '../../../components/formObjects';

function InsuredForm() {
  const { values, handleChange, errors, initialValues, setFieldValue } = useFormikContext<InitialValues<Information>>();
  const { insured, delivery_method } = values.policy;
  const { is_insured_different_from_member, member } = React.useContext(PolicyContext as React.Context<PolicyNewContext<Information>>);
  const insuredErr = errors?.policy?.insured as { [key: string]: string };

  useEffect(() => {
    if (insured.relationship_of_proposer == "person") {
      setFieldValue("policy[insured][name]", member.name)
      setFieldValue("policy[insured][birthday]", member.birthday)
      setFieldValue("policy[insured][citizen_id]", member.citizen_id)
    } else if (member.citizen_id == initialValues.policy.insured.citizen_id) {
      setFieldValue("policy[insured][name]", "")
      setFieldValue("policy[insured][birthday]", "")
      setFieldValue("policy[insured][citizen_id]", "")
    }
  }, [insured.relationship_of_proposer])

  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">要保人/被保險人基本資料</h2>
      </div>

      {is_insured_different_from_member && <h4 className="blue mt-3">要保人</h4>}


      <PureInput value={member.name} label="姓名" disabled />
      <PureInput value={member.citizen_id} label="身分證字號" disabled />
      <PureInput value={new Date(member.birthday).toLocaleDateString()} label="出生日期" disabled />


      <Row>
        <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
          <p className="text-primary">如需修改上述資料 請至會員中心進行修改</p>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={2}>
          <Form.Label>通訊地址</Form.Label>
        </Col>
        <Col sm={8} md={6} as={Row} className="p-0 m-0">
          <TownGrop value={insured.town_id} name="policy[insured][town_id]" onChange={handleChange} />
          <Form.Group as={Col} controlId="address" className="mt-2">
            <Form.Control value={insured.address} name="policy[insured][address]" isInvalid={!!insuredErr?.address} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">
              {insuredErr?.address}
            </Form.Control.Feedback>
            <div>
              <p className="text-primary m-0">
                請務必正確填寫，且不得為郵政信箱。
              </p>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <OccupationForm />
      {is_insured_different_from_member &&
        <>
          <Radio label="要保人與被保人關係" name="policy[insured][relationship_of_proposer]" size="small" options={[["本人", "person"], ["父母（要保人為被保險人之父母）", 'parentage']]} />
          {insured.relationship_of_proposer != "person" &&
            <>
              <hr />
              <h4 className="blue mt-3">被保險人</h4>
              <Input value={insured.name} name="policy[insured][name]" label="姓名" />
              <Input value={insured.citizen_id} name="policy[insured][citizen_id]" label="身分證字號" />
              <DateInput value={insured.birthday} name="policy[insured][birthday]" label="出生日期" />
            </>
          }
        </>
      }
      <div>
        {delivery_method == "email" ? (
          <p className="text-primary">環保愛地球，網路投保均提供電子保單</p>
        ) : null}
      </div>
    </div >
  );
}

export default InsuredForm;
