import * as React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FC, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Radio from '../../../components/formObjects/Radio';
import PolicyContext from '../../../components/PolicyContext';
import BeneficiaryNestedForm from './BeneficiaryNestedForm';
import { Information, InitialValues, PolicyNewContext } from '../../../policy';

const newBene = {
  ranking: 1,
  percentage: 100,
  relation_id: 1,
  name: '',
  citizen_id: '',
  birthday: '',
  mobile_phone: '',
  town_id: '',
  address: '',
};

const beneTypeOptions = [['依順序給付保險金', 'ranking'], ['依百分比分配', 'percentage']]

const BeneficiariesForm: FC = () => {
  const { relations } = useContext(PolicyContext as React.Context<PolicyNewContext<Information>>);
  const { values } = useFormikContext<InitialValues<Information>>();
  const { beneficiaries } = values.policy.insured;
  const [lastRelation, setLastRelation] = useState(0);

  useEffect(() => {
    if (beneficiaries && beneficiaries.length > 0) {
      setLastRelation([...beneficiaries].pop().relation_id);
    }
  }, [beneficiaries]);

  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">受益人資訊</h2>
      </div>
      <Radio label="給付方式" name="policy[insured][allocation]" options={beneTypeOptions} />
      <FieldArray
        name="policy[insured][beneficiaries]"
        render={(arrayHelpers) => (
          <>
            {beneficiaries && beneficiaries.map((_, index) => <BeneficiaryNestedForm index={index} key={index.toString()} relations={relations} onRemove={(i) => arrayHelpers.remove(i)} />)}

            {lastRelation != 1 ? (
              <Button variant="link" onClick={() => arrayHelpers.push(newBene)}>
                <i className="fa fa-plus" aria-hidden="true" />
                新增受益人
              </Button>
            ) : null}

          </>
        )}
      />
    </div>
  );
}

export default BeneficiariesForm;
