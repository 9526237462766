/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { FC } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { BoolProps } from './types/inputProps';
import { useField } from 'formik';

const defaultColProps = {
  title: {
    xs: 12,
  },
  input: {
    xs: 12,
  },
  error: {
    xs: 12,
  },
};

const inlineColProps = {
  input: {
    sm: 4,
    md: 3,
  },
  title: {
    sm: 8,
    md: 5,
  },
  error: {
    sm: { span: 8, offset: 4 },
    md: { span: 6, offset: 2 },
  },
};



const Boolean: FC<BoolProps> = ({
  label, name, info, inline, settings, disabled,
}) => {

  const [{ onBlur, onChange, value }, { error, touched }] = useField<boolean | string>(name);

  const handleClickAndBlur = (e) => {
    onBlur(e);
    onChange(e);
  };

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col {...(inline ? inlineColProps.title : defaultColProps.title)}>
        <Form.Label>{label || settings?.title}</Form.Label>
      </Col>
      <Col {...(inline ? inlineColProps.input : defaultColProps.input)} className={inline ? 'text-right' : ''}>
        <Form.Check
          inline
          type="radio"
          id={`${name}_true`}
          name={name}
          label="是"
          value='true'
          onChange={handleClickAndBlur}
          checked={value === true || value === "true"}
          isInvalid={touched && !!error}
          disabled={disabled}
        />
        <Form.Check
          inline
          type="radio"
          id={`${name}_false`}
          name={name}
          label="否"
          value='false'
          onChange={handleClickAndBlur}
          checked={value === false || value === "false"}
          isInvalid={touched && !!error}
          disabled={disabled}
        />
      </Col>
      <Col {...(inline ? inlineColProps.error : defaultColProps.error)}>
        <Form.Control type="hidden" disabled value={value} name={name} isInvalid={touched && !!error} readOnly />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      <Col>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

Boolean.defaultProps = {
  label: '',
  info: '',
  settings: null,
  size: 'default',
  inline: false,
  disabled: false,
};;

export default Boolean;
