import { FormikConfig, FormikValues, useFormik, yupToFormErrors } from "formik";
import {
  PetInformation,
  PolicyValues,
  TravelInformation,
  VaccineInformation,
  VehicleInformation,
} from "../../policy";
import jwtHeader from "components/_common/jwtHeader";
import validationSchema from "../../components/formObjects/validationSchema";
import { useMemo } from "react";

// declare function useFormik<Values>({  validateOnChange, validateOnBlur, validateOnMount, isInitialValid, enableReinitialize, onSubmit, ...rest}: FormikConfig<Values>)

type Information =
  | PetInformation
  | VehicleInformation
  | TravelInformation
  | VaccineInformation;

// interface InitialValue<Policy> extends FormikValues {
//   utf8: 'v',
//   authenticity_token: string,
//   policy: Policy
// }

type UsePolicyFormikConfig<IV> = {
  url: string;
  policy: IV;
  isUpdate?: boolean;
  options?: {
    validate?: (value: any) => any | null;
    errorCallback?: (error: string) => void;
    requiredInformations?: string[];
    requiredKycs?: string[];
  };
};

const getFormData = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

function usePolicyFormik<
  IV extends PolicyValues<Information> = PolicyValues<Information>
>(
  url: string,
  policy: IV,
  isUpdate?: boolean,
  options?: {
    validate?: (value: any) => any | null;
    errorCallback?: (error: string) => void;
    requiredInformations?: string[];
    requiredKycs?: string[];
  }
): ReturnType<typeof useFormik> {
  const {
    validate = null,
    errorCallback = () => {},
    requiredInformations = [],
    requiredKycs = [],
  } = options;

  const policyType = policy.policy_type;

  const schema = useMemo(
    () => validationSchema(policy.policy_type),
    [policy.policy_type]
  );

  const defaultValidate = (values) => {
    console.log(values);
    return validationSchema(policy.policy_type)
      .validate(values, { abortEarly: false })
      .then((res) => {
        console.log("VEL", res);
      })
      .catch((err) => {
        console.log("ERR", err);
        return yupToFormErrors(err);
      });
  };

  const defaultInformation = (information = {}) => {
    const values = {};
    requiredInformations.forEach((key) => {
      values[key] = null;
    });
    return { ...values, ...information };
  };

  const defaultKyc = (kyc = {}) => {
    const values = {};
    requiredKycs.forEach((key) => {
      values[key] = null;
    });
    return { ...values, ...kyc };
  };

  const initialPolicy = () => {
    const defaultValues = { ...policy };
    return {
      ...defaultValues,
      information: defaultInformation(defaultValues.information),
      kyc: defaultKyc(defaultValues.kyc),
    };
    // if (!defaultValues.information) {
    //   defaultValues.information = { type: policyType };
    //   requiredInformations.forEach((key) => {
    //     defaultValues.information[key] = null;
    //   });
    // } else {
    //   if
    // }
    // if (!policy.kyc == null) {
    //   defaultValues.kyc = {};
    //   requiredKycs.forEach((key) => {
    //     defaultValues.kyc[key] = null;
    //   });
    //   return defaultValues
    // }
  };

  const formik = useFormik<any>({
    initialValues: {
      utf8: "✓",
      authenticity_token: document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content") as string,
      policy: initialPolicy(),
    },
    validate: validate || defaultValidate,
    onSubmit: (values) => {
      console.log(values);
      window.showLoading();
      fetch(url, {
        method: `${isUpdate ? "PUT" : "POST"}`,
        headers: jwtHeader(),
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            window.location.href = res.redirect;
          } else {
            if (res.error_message) {
              errorCallback(res.error_message);
              window.scrollTo(0, 0);
              window.hideLoading();
            } else {
              window.location.reload();
            }
          }
        });
    },
  });
  return formik;
}

export default usePolicyFormik;
