export default {
  //檢測是否為ＩＥ
  isIE: (ver) => {
    ver = ver || '';
    var b = document.createElement('b')
    b.innerHTML = '<!--[if IE ' + ver + ']>1<![endif]-->'
    return b.innerHTML === '1'
  },

  //檢測是否為edge
  isEdge: () => {
    return navigator.userAgent.indexOf("Edge") > -1
  }
}