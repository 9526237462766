import * as React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
// @ts-ignore
import card1 from '@images/policies/redirect_popup/creditcard-company-1.png';
// @ts-ignore
import card2 from '@images/policies/redirect_popup/creditcard-company-2.png';
// @ts-ignore
import card3 from '@images/policies/redirect_popup/creditcard-company-3.png';
import StyledPopup from '../../../../_common/StyledPopup';

const header = { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Charset': 'utf-8' };

function RedirectPopup({ paymentForm = null }) {
  if (!paymentForm) return null;
  return (
    <StyledPopup noCloseBtn modal open disable>
      <div className="p-0 p-md-5 text-center">
        <h5>
          您的投保資料已送至保險公司
        </h5>
        <p>
          限使用信用卡繳費
          <br className="d-block d-md-none" />
          (不包含簽帳金融卡、現金卡、大來卡)
          <br />
          信用卡處理中心NCCC
          <br className="d-block d-md-none" />
          <strong className="text-error">不接受</strong>
          以下信用卡做保險繳款
        </p>
        <Row>
          <Col xs={4}>
            <Card className="shadow my-3 overflow-hidden">
              <img src={card1} alt="美國運通卡" />
            </Card>
          </Col>
          <Col xs={4}>
            <Card className="shadow my-3 overflow-hidden">
              <img src={card2} alt="花旗銀行" />
            </Card>
          </Col>
          <Col xs={4}>
            <Card className="shadow my-3 overflow-hidden">
              <img src={card3} alt="郵局金融卡" />
            </Card>
          </Col>
        </Row>
        <p>請按下確認鍵進行繳款。</p>
        <form id="redirect_form" action={paymentForm.redirect_url} acceptCharset="utf-8" method="post">
          {paymentForm.body && Object.keys(paymentForm.body).map((key) => (
            <input type="hidden" value={paymentForm.body[key]} name={key} />
          ))}
          <Row>
            <Col xs={6} className="m-auto">
              <Button variant="primary" size="lg" type="submit" onClick={() => window.showLoading()}>確認</Button>
            </Col>
          </Row>
        </form>
      </div>
    </StyledPopup>

  );
}

export default RedirectPopup;
