import * as React from 'react';
import { useContext } from 'react';
import { useFormikContext } from 'formik';
// @ts-ignore
import policySettings from '@config/policy_setting.yml';
import { Input, Checkbox, Select, Boolean } from '../../../components/formObjects';
import PolicyContext from '../../../components/PolicyContext';
import { InitialValues, PolicyNewContext, KycSettingsType, Information } from '../../../policy';

function KycsForm() {
  const { kyc: kycSettings } = policySettings.default as KycSettingsType;
  const { requiredKycs } = useContext(PolicyContext as React.Context<PolicyNewContext<Information>>);
  const { handleChange, values } = useFormikContext<InitialValues<any>>();
  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">告知事項</h2>
      </div>
      <p className="text-primary">請您務必親自據實回答，以免影響您的保單效力及合法權益。</p>
      {
        requiredKycs.map((key) => {
          const {
            title, input_type: type, options, relation,
          } = kycSettings[key];
          if (!relation || (values.policy.kyc[relation.key] && (values.policy.kyc[relation.key] == relation.when || values.policy.kyc[relation.key]?.includes(relation.when.toString())))) {
            switch (type) {
              case 'text':
                return <Input key={key} label={title} name={`policy[kyc][${key}]`} settings={kycSettings[key]} />;
              case 'bool':
                return <Boolean key={key} label={title} name={`policy[kyc][${key}]`} settings={kycSettings[key]} />;
              case 'select':
                return <Select key={key} label={title} name={`policy[kyc][${key}]`} options={options && Object.keys(options).map((k, i) => [options[k], k])} defaultOption settings={kycSettings[key]} />;
              case 'checkbox':
                return <Checkbox key={key} label={title} name={`policy[kyc][${key}]`} options={options && Object.keys(options).map((k, i) => [options[k], i])} settings={kycSettings[key]} />;
              default:
                return null;
            }
          }
        })
      }
    </div>
  );
}

export default KycsForm;
