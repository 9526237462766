import * as React from 'react';
import { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Form, Col, Row } from 'react-bootstrap';
import { useField } from 'formik';


interface Props {
  disabled?: boolean,
  label?: string,
  name: string,
  info?: string,
  options?: { [key: string]: any },
  settings?: { [key: string]: any },
  onChange?(value: (string | Date)): void
}


const DatePicker: React.FC<Props> = ({
  disabled, label, name, onChange, info, options, settings,
}) => {
  // const [inputSettings] = useState(() => {
  //   const state: Props['settings'] = { ...settings };
  //   if (label) state.title = label;
  //   if (info) state.info = info;
  //   return state;
  // });

  // const {
  //   error: err, setFieldTouched, setFieldValue,
  // } = useFormikInputProps('', error, name);

  const [{ }, { error, touched }, { setValue, setTouched }] = useField(name);

  return (
    <Flatpickr
      disabled={disabled}
      options={options}
      onChange={(value) => {
        if (onChange) onChange(value[0]);
        setValue(value[0].toISOString());
      }}
      onClose={() => { setTouched(true) }}
      render={
        ({ defaultValue }, ref) => (
          <Form.Group as={Row} controlId={name} className="my-3">
            <Col sm={4} md={2}>
              <Form.Label>{label || settings?.title}</Form.Label>
            </Col>
            <Col sm={8} md={6}>
              <Form.Control
                value={defaultValue || ''}
                name={name}
                type="text"
                disabled={disabled}
                isInvalid={touched && !!error}
                onChange={() => { }}
                ref={ref}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Col>
            <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
              <Form.Text>{info || settings?.info}</Form.Text>
            </Col>
          </Form.Group>
        )
      }
    />
  );
}



DatePicker.defaultProps = {
  disabled: false,
  label: '',
  info: '',
  onChange: null,
  options: {},
  settings: null,
};

export default DatePicker;
