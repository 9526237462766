import { useField } from 'formik'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

type Props = {
  name: string;
  label: string;
}

const thisYear = () => {
  const year = new Date().getFullYear();
  const yearsOptions = []
  for (let i = year; i > year - 50; i--) {
    yearsOptions.push(i)
  }
  return yearsOptions;
}

const YearAndDateInput = ({ name, label }) => {
  const [year, setYear] = useState(() => new Date().getFullYear().toString())
  const [month, setMonth] = useState(() => (new Date().getMonth() + 1).toString().padStart(2, '0'))
  const [inputProps, metaProps, inputHelper] = useField(name)

  useEffect(() => {
    const value = inputProps.value
    if (value) {
      const yearAndMonth = value.split("-")
      if (yearAndMonth.length == 2) {
        setYear(yearAndMonth[0])
        setMonth(yearAndMonth[1])
      }
    }
  }, [])

  useEffect(() => {
    inputHelper.setValue(`${year}-${month}`)
  }, [year, month]);
  return (
    <Form.Group as={Row} className="my-3">
      <input hidden {...inputProps} name={name} />
      <Col sm={4} md={2}>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col sm={4} md={3} >
        <Form.Control
          as="select"
          value={year}
          onChange={(e) => {
            setYear(e.target.value)
            inputHelper.setTouched
          }}
        >
          <option value="">請輸入年份</option>
          {thisYear().map(y => (
            <option key={y} value={`${y}`}>{`${y} 年`}</option>
          ))}
        </Form.Control>
      </Col>
      <Col sm={4} md={3} >
        <Form.Control
          as="select"
          value={month}
          onChange={(e) => {
            setMonth(e.target.value)
            inputHelper.setTouched
          }}
        >
          <option value="">請輸入月份</option>
          <option value="01">1月</option>
          <option value="02">2月</option>
          <option value="03">3月</option>
          <option value="04">4月</option>
          <option value="05">5月</option>
          <option value="06">6月</option>
          <option value="07">7月</option>
          <option value="08">8月</option>
          <option value="09">9月</option>
          <option value="10">10月</option>
          <option value="11">11月</option>
          <option value="12">12月</option>
        </Form.Control>

      </Col>
      {metaProps.touched && metaProps.error && (
        <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
          <Form.Text className="text-error">{metaProps.error}</Form.Text>
        </Col>
      )}
    </Form.Group>
  )
}

export default YearAndDateInput
