import React, { useState } from 'react'
import {
  Form, Col, Row,
} from 'react-bootstrap';
import {
  MyInputGroup as InputGroup,
  Selector,
} from '@/components/_common/FormInput'
import useOccupation from '../../../pages/policy/New/hooks/useOccupation';
import { setNestedObjectValues, useField } from 'formik';


function OccupationInput({ name }) {
  const [{ value }, , { setValue, setTouched }] = useField(name)
  window.setValue = setValue
  console.log(setValue)
  const { code, options, values, handleCodeChange } = useOccupation(value, setValue)

  return (
    <InputGroup label="職業" name="occupation">
      <Row>
        <Col xs={12} sm={4} className="mb-2">
          <Selector
            value={values.type}
            name="occupation-type"
            onChange={handleCodeChange}
          >
            <option value="">請選擇</option>
            {options && options.types.map(c => <option value={c[1]} key={c[1]}>{c[0]}</option>)}
          </Selector>
        </Col>

        <Col xs={12} sm={4} className="mb-2">
          <Selector
            value={values.class}
            onChange={handleCodeChange}
          >
            <option value="">請選擇</option>
            {options && options.classes.map(c => <option value={c[1]} key={c[1]}>{c[0]}</option>)}
          </Selector>
        </Col>

        <Col xs={12} sm={4} className="mb-2">
          <Selector
            value={values.occupation}
            onChange={handleCodeChange}
            onBlur={() => setTouched(true)}
          >
            <option value="">請選擇</option>
            {options && options.occupations.map(occupation => <option value={occupation[1]} key={occupation[1]}>{occupation[0]}</option>)}
          </Selector>
        </Col>
      </Row>
    </InputGroup>
  )
}

export default OccupationInput

