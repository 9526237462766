import * as React from 'react';
import { FC, useContext } from 'react';
import PolicyContext from '../../../components/PolicyContext';
import { PolicyEditContext } from '../../../policy';

const toDateTimeString = (string: string) => {
  if (!string) return '';
  return `${new Date(string).toLocaleDateString()} ${new Date(string).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

const TravelAnalysis = () => {
  const { policy, popup } = useContext(PolicyContext) as PolicyEditContext;
  return (
    <>
      <h5>
        保險需求
      </h5>

      <ol>
        <li>
          是否有指定之保險公司: 是，指定保險公司名稱:
          {' '}
          {popup.insuranceCompany?.name}
        </li>
        <li>
          保險期間:
          {' '}
          {toDateTimeString(policy?.insured_from)}
          {' '}
          至
          {' '}
          {toDateTimeString(policy?.insured_till)}
          {' '}
        </li>
        <li>被保險人於本次旅程是否已投保其他保險公司之旅行平安險: </li>
      </ol>

      <p>
        預估繳交保險費金額新台幣:
        {' '}
        {policy?.premium}
        元整
      </p>

      <h5>本保險經紀人公司根據要保人/被保人所提供各種資訊及需求之建議內容</h5>

      <ol>
        <li>
          保險公司名稱:
          {' '}
          {popup.insuranceCompany?.name}
          ，保險公司概況:
          {' '}
          {popup.insuranceCompany?.url}
        </li>
        <li>保險險種名稱: 旅遊平安險</li>
        <li>
          保險金額:
          {' '}
          {policy?.amount}
          萬元整。
        </li>
        <li>保障範圍: 詳保險契約條款</li>
        <li>
          保險費:
          {' '}
          {policy?.premium}
          元整。
        </li>
        <li>建議投保保險公司理由: 客戶指定。</li>
      </ol>
    </>
  );
}

export default TravelAnalysis;
