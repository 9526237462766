import * as React from 'react'
import { InitialValues, PolicyNewProps, Member, Information, PolicyType, PolicyValues } from '../policy';
import usePolicyFormik from './hooks/usePolicyFormik';
import { PolicyProvider } from 'components/pages/policy/components/PolicyContext';
import { Alert, Button, Form } from 'react-bootstrap';
import { FormikProvider } from 'formik';
import FormikErrorFocus from 'components/_common/FormikErrorFocus';
import TravelForm from './Travel';
import PetForm from './Pet';
import VehicleForm from './Vehicle';
import VaccineFrom from './Vaccine';
import OtherForm from './components/subForm/OtherForm';

const New: React.FC<PolicyNewProps<Information>> = ({
  member, policy, requiredInformations, requiredKycs, options, next, update, error, memo
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string>(error || '');
  const formik = usePolicyFormik<PolicyValues<Information>>(next, policy, update, {
    requiredInformations,
    requiredKycs,
    errorCallback: setErrorMessage
  })

  const policyType: PolicyType = policy.policy_type

  const renderForm = () => {
    switch (policyType) {
      case PolicyType.travel:
        return <TravelForm />
      case PolicyType.pet:
        return <PetForm />
      case PolicyType.car:
        return <VehicleForm />
      case PolicyType.motor:
        return <VehicleForm />
      case PolicyType.vaccine:
        return <VaccineFrom />
    }
  }

  return (
    <div>
      <PolicyProvider value={{ ...options, requiredInformations, requiredKycs, member }}>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} id="policy_from">
            {renderForm()}
            {memo ? <p className="text-error">{memo}</p> : null}
            <OtherForm />
            <div className="text-center py-4">
              <Button onClick={() => window.history.back()} className="px-4 py-2 m-3 h4">上一步</Button>
              <Button variant="outline-primary" type="submit" className="px-4 py-2 m-3 h4">
                下一步
              </Button>
            </div>
            <FormikErrorFocus
              offset={-80}
              align="top"
              focusDelay={200}
              ease="linear"
              duration={500}
            />
          </Form>
        </FormikProvider>
      </PolicyProvider>
    </div>
  )
}

export default New
