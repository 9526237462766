import { useField } from 'formik';
import * as React from 'react'
import { FC } from 'react'
import { Form, Col, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { DateInputProps } from './types/inputProps';

const mask = [/[1-2]/, /(0|9)/, /[0-9]/, /[0-9]/, /-/, /[0-1]/, /[0-9]/, /-/, /[0-3]/, /[0-9]/]

const DateInput: FC<DateInputProps> = ({ label, placeholder, name, info, settings }) => {
  const [{ onBlur, onChange, value }, { error, touched }, { setValue }] = useField(name);

  const handelMaskedValueChange = (newState, oldState) => {
    const { value, selection } = newState;
    const tmpMask = [...mask];
    if (selection != null && selection.end != null) {
      let { end } = selection;
      if (end == 5 || end == 8) {
        end -= 1;
      }

      // 日
      if (selection.end == 9) {
        const Tens = value[8];
        Tens == 3
          ? (tmpMask[9] = /(0|1)/)
          : Tens == 0
            ? (tmpMask[9] = /[1-9]/)
            : (tmpMask[9] = /[0-9]/);
      }
      // 月
      if (selection.end == 6) {
        const Tens = value[5];
        Tens == 1 ? (tmpMask[6] = /[0-2]/) : (tmpMask[6] = /[1-9]/);
      }
      // 年 百位數
      if (selection.end == 1) {
        const th = value[0];
        th == 2 ? (tmpMask[1] = /0/) : (tmpMask[1] = /9/);
      }
      if (!RegExp(tmpMask[end - 1]).test(value[end - 1])) {
        return oldState;
      }
    }

    return newState;
  };

  return (
    <InputMask
      value={value}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      mask="9999-99-99"
      maskChar=" "
      disabled={false}
      beforeMaskedValueChange={handelMaskedValueChange}
    >
      {() => (
        <Form.Group as={Row} controlId={name} className="my-3">
          <Col sm={4} md={2}>
            <Form.Label>{label || settings?.title}</Form.Label>
          </Col>
          <Col sm={8} md={6}>
            <Form.Control
              defaultValue={value || ''}
              name={name}
              type="text"
              placeholder={placeholder || settings?.placeholder}
              isInvalid={touched && !!error}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </Col>
          <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
            <Form.Text>{info || settings?.info}</Form.Text>
          </Col>
        </Form.Group>
      )}
    </InputMask>
  )
}

export default DateInput