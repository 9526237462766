/** T : Give a InformationType */

export interface PropsOfMember {
  citizen_id: string
  name: string
  birthday: string
}
export interface PolicyNewProps<T> {
  /** Member id, name and birthday*/
  member: PropsOfMember
  /** New Policy data */
  policy: PolicyValues<T>;
  /** Required policy information(set by package) */
  requiredInformations: string[];
  /** Required policy know your customer questionnaire (set by package) */
  requiredKycs: string[];
  /** Some options of policy*/
  options: any;
  /** Next step page url or api*/
  next: string;
  /** In update mode */
  update: boolean;
  /** Error message */
  error: string;
  /** Package Memo */
  memo: string;
}

/** [ travel | pet | car | motor | vaccine | accident ] */
export enum PolicyType {
  travel = 'travel',
  pet = 'pet',
  car = 'car',
  motor = 'motor',
  vaccine = 'vaccine',
  accident = 'accident'
}

/** [ initialized | transacted | successed | terminated | cancel ] */
export enum PolicyStatus {
  initialized = 'initialized',
  transacted = 'transacted',
  successed = 'successed',
  terminated = 'terminated',
  cancel = 'cancel',
}
/** [ pending | confirming | paying | completed | returned ] */
export enum PolicyFilingStatus {
  pending = 'pending',
  confirming = 'confirming',
  paying = 'paying',
  completed = 'completed',
  returned = 'returned',
}

export type PolicyValues<T> = {
  member_id: number
  insured_from: string
  insured_till: string
  plan_id: number
  package_id: number
  apply_number: number
  policy_type: PolicyType
  status: PolicyStatus
  filing_status: PolicyFilingStatus
  amount: number
  duration: number
  delivery_method: 'email' | 'post'
  premium: number
  sales_number: string
  /** [我已閱讀書面分析報告 */
  is_analysis_read: boolean
  /** [我已閱讀網路投保應注意事項 */
  is_on_line_insurance_attention_read: boolean
  /** [我已閱讀保單條款 */
  is_contract_read: boolean
  /** [我已閱讀個人資料告知與同意事項聲明書 */
  is_personal_capital_read: boolean
  /** [我已閱讀個資宣告書 */
  is_privacy_policy_read: boolean
  /** [我已閱讀「消費者網路投保需求聲明事項」 */
  is_kyc_read: boolean
  /** [我已閱讀保單內容 */
  is_policy_content_read: boolean
  information?: T
  kyc?: PolicyKyc
  insured?: Insured
}

//********************* Policy Infromation ****************************/

export type TravelInformation = {
  activity_name?: string
  flight_date?: string | Date
  flight_number?: string
  mountain?: string
  passport_english_name?: string
  passport_number?: string
  type: PolicyType.travel
}

/** [ cat | dog ] */
export enum PetPolicyInformationPetKind {
  dog = 'dog',
  cat = 'cat'
}
/** [ female | male ] */
export enum PetPolicyInformationGender {
  female = 'female',
  male = 'male'
}
/** ['etag' | 'blood' | 'exam' | 'injection'] */
export enum PetPolicyInformationCertifiedDocumention {
  etag = 'etag',
  blod = 'blood',
  exam = 'exam',
  injection = 'injection'
}

export type PetInformation = {
  pet_name?: string
  pet_kind?: PetPolicyInformationPetKind
  pet_birthday?: string
  pet_race?: string
  pet_gender?: PetPolicyInformationGender
  pet_weight?: string
  pet_color?: string
  certified_document?: PetPolicyInformationCertifiedDocumention
  document_file?: File
  picture_file?: File
  type: PolicyType.pet
}

export type VehicleInformation = {
  citizen_id: string,
  car_kind?: string,
  car_exhaust?: string,
  car_model?: string,
  compulsory_number?: string,
  date_car_made?: string,
  date_compulsory_insurance?: string,
  date_voluntary_insurance?: string,
  date_license?: string,
  engine_num?: string,
  is_business_vehicle?: boolean,
  is_electric?: boolean,
  license_plate_first?: string,
  license_plate_last?: string,
  passengers_number?: string,
  policy_number_compulsory?: string,
  policy_number_voluntary?: string,
  real_amount?: number,
  type: PolicyType
}

export type VaccineInformation = {
  renew_insurance: boolean
}

export type Information = TravelInformation | PetInformation | VaccineInformation | VehicleInformation

//********************* Policy KYC ****************************/

export type PolicyKyc = {
  is_career_type_about_laundering_money?: boolean;
  is_buy_other_plan?: boolean;
  is_buy_other_travel_plan?: boolean;
  is_pregnant?: boolean;
  is_guardian_declare?: boolean;
  career_options?: string;
  job_title_options?: string;
  is_live_abroad?: boolean;
  has_political_job?: boolean;
  personal_purpose_and_demand?: string;
  does_not_care_plan_product?: boolean;
  source_of_insurance_expenses?: string;
  source_of_insurance_expenses_remark?: string;
  cases_of_contract_change_loan_borrow_in_three_months?: boolean;
  has_other_country_id_or_not_born_in_roc?: boolean;
  understanding_insured_fee?: boolean;
  ctbc_source_of_insurance_expenses?: string;
  stay_abroad_half_year_or_current_stay_abroad?: boolean;
  personal_income?: string;
  whole_family_income?: string;
  family_main_income_amount?: string;
  family_main_income_provider?: string;
  self_manage?: boolean;
  renew_insurance?: boolean;
  other_infectious_policy?: boolean;
  other_infectious_policy_remark?: string;
  other_vaccine_policy?: boolean;
  other_vaccine_policy_remark?: string;
  physical_disabilities?: boolean;
  other_pet_policy?: boolean;
  already_vaccinated_in_one_year?: boolean;
  treatment_received_in_two_months?: boolean;
  pet_physical_disabilities: boolean;
  medical_exam_abnormal: boolean;
  mega_no_other_travel_policy?: boolean;
  is_electric?: boolean;
  [key: string]: any
}

export type Insured = {
  readonly id: number | null
  readonly policy_id: number | null
  readonly plan_id: number | null
  readonly sex: number | null
  citizen_id: string
  name: string
  birthday: string
  postal_code: string
  town_id: number | null
  address: string
  mobile_phone: string
  email: string
  allocation: 'percentage' | 'ranking'
  readonly old_table_id: null
  relationship_of_proposer: "person" | "parentage" | boolean
  readonly created_at: string | null
  readonly updated_at: string | null
  beneficiaries?: Beneficiary[]
  update_member_data: boolean
}

type Beneficiary = {
  readonly id: number | null
  readonly insured_id: number | null
  citizen_id: string | null
  name: string | null
  readonly sex: null
  birthday: string | null
  postal_code: string | null
  address: string | null
  readonly home_phone
  mobile_phone: string | null
  email: string | null
  relationship: 'heir_at_law' | 'spouse' | 'father' | 'mother' | 'child' | 'employee' | 'friend' | 'relative' | 'sibling' | 'self' | 'other'
  percentage: number | null
  ranking: number | null
  readonly created_at: string | null
  readonly updated_at: string | null
  relation_id: number
  readonly no_more_beneficiaries: boolean
  town_id: number | null
  readonly old_insured_id: null
}

export type Member = {
  address: string
  birthday: string
  citizen_id: string
  email: string
  xname: string
  mobile_phone: string
}

export type InsuranceCompany = {
  name: string
  code: string
  contract_url: string
  url: string
}

/** [Policy form initial values */
//***** initial value for formik */

export interface InitialValues<T> {
  utf8: string
  authenticity_token: string;
  policy: PolicyValues<T>
}


/** [Travel formik values */
export type TravelInitialValues = InitialValues<TravelInformation>
/** [Pet formik values */
export type PetInitialValues = InitialValues<PetInformation>
/** [Car formik values */
export type VehicleInitialValues = InitialValues<VehicleInformation>
/** [Vaccine formik values */
export type VaccineInitialValues = InitialValues<VaccineInformation>

// export type InitialValues = TravelInitialValues | PetInitialValues | VehicleInitialValues | VaccineInitialValues

//************* Policy Context ****************/

export interface PolicyNewContext<Information> {
  member: PropsOfMember
  requiredInformations: string[]
  requiredKycs: string[]
  policy: PolicyValues<Information>
  relation?: string[][]
  pet_kind?: string[]
  pet_gender?: string[]
  pet_race?: string[]
  activities?: string[]
  abroad?: boolean
  continents?: string[][]
  relations?: string[][]
  car_kinds?: string[][]
  car_models?: string[][]
  has_compulsory_insurance: boolean,
  has_voluntary_insurance: boolean
  is_insured_different_from_member?: boolean,
}

export interface PolicyEditContext {
  insured: Insured;
  member: Member;
  policy: PolicyValues<Information>;
  popup?: {
    kycRead: string;
    personalCapital: string;
    insuranceCompany: InsuranceCompany;
  }
}


//***************** settings ************/

export type KycSettingsType = {
  [key: string]: {
    title?: string;
    info?: string;
    placeholder?: string;
    options?: string[] | string[][] | { [key: string | number]: string | string[] };
  }
}




export interface PolicySettings {
  [key: string]: PolicySetting
}

export interface PolicySetting {
  type: string;
  input_type?: string;
  title?: string;
  required?: boolean;
  placeholder: string;
  options?: string[] | { [key: string]: number | string } | string[][]
  info?: string;
}
