import React, { Component, useContext } from 'react'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import { Col, Row, Container } from 'react-bootstrap'
import MemberBox from '@/components/Member/common/MemberBox'
import themes from '@/components/Themes'
import { ThemeProvider } from 'styled-components'
import MemberList from '@/components/Member/common/MemberList'
import RouteWithSubRoutes from './components/RouteWithSubRoute'
import routes from './routes'
import Index from '@/components/Member/Index'
import Edit from '@/components/Member/Edit'
import Bonus from '@/components/Member/Bonus'
import Notifications from '@/components/Member/Notifications'
import Share from '@/components/Member/Share'
import Policies from '@/components/Member/Policies/index'
import PolicyShow from '@/components/Member/Policies/PolicyShow'
import MemberContext from '@/components/_context/MemberContext'

function Layout({ routes }) {
  const { currentMember } = useContext(MemberContext)
  return (
    <Container className="pt-3 pt-md-5">
      <Row>
        <Col sm={3} className="d-none d-md-block">
          <MemberBox
            memberName={currentMember && currentMember.name}
            gender={currentMember && currentMember.gender}
          />
          <MemberList />
        </Col>
        <Col sm={12} md={9}>
          <RouteWithSubRoutes routes={routes} />
        </Col>
      </Row>
    </Container>
  )
}

export default Layout
