import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useField } from 'formik';
import * as moment from 'moment';
import styled from 'styled-components';

type PureInputProps = {
  disabled?: boolean,
  label?: string,
  placeholder?: string,
  name?: string,
  info?: string,
  type?: string,
  value: string,
  onBlur?: (e: any) => void,
  onChange?: (e: any) => void,
}

const PureInput: React.FC<PureInputProps> = ({
  disabled, label, placeholder, name, info, type, value, onBlur, onChange
}) => {

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col sm={8} md={6}>
        <Group>
          <Form.Control
            value={value}
            name={name}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Group>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

PureInput.defaultProps = {
  disabled: false,
  label: '',
  info: '',
  placeholder: '',
  type: 'text',
  onChange: () => { },
  onBlur: () => { }
};

const Group = styled.div`
  position: relative;
`

export default PureInput;
