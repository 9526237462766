import { useField, useFormikContext } from 'formik';
import * as React from 'react'
import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { AgreementProps } from './types/inputProps';

const Agreement: FC<AgreementProps> = ({ name, label, children }) => {

  const [{ onBlur, onChange, value }, { error, touched }] = useField<boolean | undefined>(name);

  const handleChecked = (e) => {
    onChange(e);
    onBlur(e);
  };

  return (
    <Form.Check type="checkbox" id={name} style={{ flexWrap: 'wrap' }}>
      <Form.Check.Input
        type="checkbox"
        isInvalid={touched && !!error}
        name={name}
        onChange={handleChecked}
        checked={value}
        className="d-block my-3"
      />
      <Form.Check.Label>
        {label || children}
      </Form.Check.Label>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Check>
  );
}

export default Agreement;
