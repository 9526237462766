import * as React from 'react';
import * as moment from 'moment';
import { useFormikContext } from 'formik';
import Input from '../../components/formObjects/Input';
import InformationForm from './InformationForm';
import { TravelInitialValues } from '../../policy';

function BasicInfo() {
  const { values } = useFormikContext<TravelInitialValues>();
  const { policy } = values;
  return (
    <div className="fields">
      <div className="fancy-title title-bottom-border">
        <h2 className="font-weight-normal">投保資料</h2>
      </div>
      <Input disableFormik value={`${policy.amount}萬`} name="policy[amount]" label="主約保額" disabled />
      <Input disableFormik value={moment(policy.insured_from).format('YYYY/MM/DD HH:mm')} name="policy[insured_from]" label="出發時間" disabled />
      <Input disableFormik value={moment(policy.insured_till).format('YYYY/MM/DD HH:mm')} name="policy[insured_till]" label="回程時間" disabled />
      <Input disableFormik value={`${policy.duration}天`} name="policy[duration]" label="天數" disabled />
      <InformationForm />
      <div className="alert alert-primary" role="alert">
        <i className="fas fa-exclamation-triangle" />
        外交部規定之紅色警戒區域不予承保
        <a href="https://www.boca.gov.tw/sp-trwa-list-1.html" rel="noopener noreferrer" target="_blank">外交部國外旅遊警示分級表 </a>
        <br />
        <i className="fas fa-exclamation-triangle" />
        依法令規定，保險公司之非有效契約客戶投保上限為1,200萬
        <br />
        【備註1】同時根據投保人的年齡、各保險公司政策以及是否為該保險公司之有效契約保戶，各家保險公司保留非有效契約客戶之投保保額上限，實際可投保金額請依照系統顯示為主。
        <br />
        {' '}
        【備註2】「有效契約客戶」指投保時同時擁有該公司之其他保險契約
      </div>
    </div>
  );
}

export default BasicInfo;
