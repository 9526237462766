import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useFormikContext } from 'formik';
import TownGrop from 'components/Member/common/TownGrop';
import BirthdayInput from 'components/Member/Edit/Info/BirthdayInput';
import Select from '../../../components/formObjects/Select';
import Input from '../../../components/formObjects/Input';
import DateInput from '../../../components/formObjects/DateInput';
import { Information, InitialValues } from '../../../policy';

const rankingOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => [i, i]);
const percentageOptions = Array.from({ length: 100 }, (_, i) => [`${100 - i}%`, 100 - i]);

interface Props {
  index: number
  relations: string[][]
  onRemove(index: number): void
}

const BeneficiaryNestedForm: FC<Props> = ({ index, relations, onRemove }) => {
  const { values, handleChange, handleBlur, errors, touched,
  } = useFormikContext<InitialValues<Information>>();
  const { insured } = values.policy
  const bene = insured.beneficiaries[index];
  const benesLength = insured.beneficiaries.length;
  const { allocation: shareType } = insured;
  const [relationShipOptions, setRelationShipOptions] = useState(relations);
  const beneTouched = touched?.policy?.insured?.beneficiaries && touched?.policy?.insured?.beneficiaries[index];
  const beneError = errors?.policy?.insured?.beneficiaries && errors?.policy?.insured?.beneficiaries[index];
  const nameProp = `policy[insured][beneficiaries][${index}]`;

  const isError = (name) => {
    if (!beneTouched || !beneTouched[name]) return false;
    return beneError && beneError[name];
  };

  useEffect(() => {
    if (benesLength - 1 > index) {
      setRelationShipOptions(() => {
        const state = [...relations];
        state.shift();
        return state;
      });
    } else {
      setRelationShipOptions(relations);
    }
  }, [benesLength]);

  return (
    <>
      {shareType == 'percentage'
        ? <Select label="百分比" name={`${nameProp}[percentage]`} options={percentageOptions} size="small" />
        : <Select label="給付排名" name={`${nameProp}[ranking]`} options={rankingOptions} size="small" />}
      <Select label="關係" name={`${nameProp}[relation_id]`} options={relationShipOptions} size="small" />
      <Row>
        <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
          <p className="text-primary">倘身故受益人指定為法定繼承人者，保險金之順位及應得比例適用民法繼承編相關規定。  </p>
        </Col>
      </Row>
      {bene.relation_id != 1 ? (
        <>
          <input name={`${nameProp}[_destroy]`} hidden defaultValue="false" />
          <Input label="姓名" name={`${nameProp}[name]`} />
          <Input label="身分證字號" name={`${nameProp}[citizen_id]`} />
          <DateInput label="生日" name={`${nameProp}[birthday]`} />
          <Input label="手機" name={`${nameProp}[mobile_phone]`} />
          <Row>
            <Col sm={4} md={2}>
              <Form.Label>通訊地址</Form.Label>
            </Col>
            <Col sm={8} md={6} as={Row} className="p-0 m-0">
              <TownGrop name={`${nameProp}[town_id]`} value={bene?.town_id} onChange={handleChange} />
              <Form.Group as={Col} controlId="address" className="mt-2">
                <Form.Control name={`${nameProp}[address]`} value={bene?.address} isInvalid={isError('address')} onChange={handleChange} onBlur={handleBlur} />
                <Form.Control.Feedback type="invalid">
                  {isError('address')}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </>
      ) : null}

      {benesLength > 1
        ? (
          <Button variant="link" onClick={() => onRemove(index)}>
            <i className="fa fa-minus" aria-hidden="true" />
            移除受益人
          </Button>
        )
        : null}
      <hr />

    </>
  );
}

export default BeneficiaryNestedForm;
