import { useField } from 'formik';
import * as React from 'react';
import { FC } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { FileInputProps } from './types/inputProps';
import useFormikInputProps from './useFormikInputProps';

const File: FC<FileInputProps> = ({
  disabled, label, name, info, settings, accept,
}) => {

  const [{ onBlur, onChange, value }, { error, touched }, { setValue }] = useField(name);

  const handleChange = async (e) => {
    const fileReader = (data) => new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(data)
    })
    const file = await fileReader(e.currentTarget.files[0])
    setValue(file);
  };

  return (
    <Form.Group as={Row} controlId={name} className="my-3">
      <Col sm={4} md={2}>
        <Form.Label>{label || settings?.title || ''}</Form.Label>
      </Col>
      <Col sm={8} md={6}>
        <Form.Control type="file" name={name} disabled={disabled} onChange={handleChange} onBlur={onBlur} isInvalid={touched && !!error} accept={accept} />
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Col>
      <Col sm={{ span: 8, offset: 4 }} md={{ span: 6, offset: 2 }}>
        <Form.Text>{info || settings?.info}</Form.Text>
      </Col>
    </Form.Group>
  );
}

File.defaultProps = {
  disabled: false,
  label: '',
  info: '',
  settings: null,
  accept: '*',
};

export default File;
